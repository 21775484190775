import React, { Fragment } from "react";

// React Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faCheck } from "@fortawesome/free-solid-svg-icons";

// CSS
import "../../static/css/assets/form-item.css";

const FormItem = ({
  itemName,
  itemValue,
  itemLabel,
  itemOnChange,
  itemType = "text",
  placeholder = "placeholder",
  required = false,
  selectPlaceholder = null,
  selectOptions = [],
  onFormSubmit,
  submitLabel = "Quero Assinar!",
  sideLink,
  sideLinkLabel,
}) => {
  switch (itemType) {
    case "text":
      return (
        <div className="landing--form-item">
          <input
            name={itemName}
            value={itemValue}
            onChange={itemOnChange}
            type="text"
            placeholder={`${placeholder}${required ? "*" : ""}`}
          />
        </div>
      );
    case "email":
      return (
        <div className="landing--form-item">
          <input
            name={itemName}
            value={itemValue}
            onChange={itemOnChange}
            type="email"
            placeholder={`${placeholder}${required ? "*" : ""}`}
          />
        </div>
      );
    case "textarea":
      return (
        <div className="landing--form-item-textarea">
          <textarea
            name={itemName}
            value={itemValue}
            onChange={itemOnChange}
            type="text"
            placeholder={`${placeholder}${required ? "*" : ""}`}
          />
        </div>
      );
    case "checkbox":
      return (
        <div className="flex-c cc">
          <div className="flex-r ss">
            <label className="form-label text-semibold">{itemLabel}</label>
            {sideLink ? (
              <a className="text-muted float-end" href={sideLink}>
                {sideLinkLabel}
              </a>
            ) : null}
          </div>
          <div className="checkbox-option" style={{ display: "inline-flex" }}>
            <label className="checkbox-container">
              <input
                type="checkbox"
                name={itemName}
                checked={itemValue}
                onChange={itemOnChange}
              />
              <span>{placeholder}</span>
              <span className="checkmark">
                <FontAwesomeIcon className="checkmark-icon" icon={faCheck} />
              </span>
            </label>
          </div>
        </div>
      );
    case "select":
      return selectOptions.length === 0 ? null : (
        <div className="landing--form-item">
          <select
            name={itemName}
            value={itemValue}
            onChange={itemOnChange}
            style={itemValue === "" ? { color: "#707070" } : null}
          >
            {selectPlaceholder ? (
              <option value="" disabled hidden>
                {`${selectPlaceholder}${required ? "*" : ""}`}
              </option>
            ) : null}
            {selectOptions.map((option) => (
              <option key={option}>{option}</option>
            ))}
          </select>
        </div>
      );
    case "selectEstadosBR":
      return selectOptions.length === 0 ? null : (
        <div className="landing--form-item">
          <select
            name={itemName}
            value={itemValue}
            onChange={itemOnChange}
            style={itemValue === "" ? { color: "#707070" } : null}
          >
            <option value="" disabled hidden>
              {`${selectPlaceholder}${required ? "*" : ""}`}
            </option>
            {selectOptions.map((option) => (
              <option key={option.id} value={option.sigla}>
                {option.sigla}
              </option>
            ))}
          </select>
        </div>
      );
    case "selectCidadesBR":
      return (
        <div className="landing--form-item">
          <select
            name={itemName}
            value={itemValue}
            onChange={itemOnChange}
            style={itemValue === "" ? { color: "#707070" } : null}
          >
            {selectOptions.length === 0 ? (
              <Fragment>
                <option value="" disabled hidden>
                  {`${selectPlaceholder}${required ? "*" : ""}`}
                </option>
                <option value="null" disabled>
                  "escolha um estado..."
                </option>
              </Fragment>
            ) : (
              <option value="" disabled hidden>
                {selectPlaceholder}
              </option>
            )}
            {selectOptions.map((option) => (
              <option key={option.id} value={option.nome}>
                {option.nome}
              </option>
            ))}
          </select>
        </div>
      );
    case "selectNeighborhoodsRio":
      return (
        <div className="landing--form-item">
          <select
            name={itemName}
            value={itemValue}
            onChange={itemOnChange}
            style={itemValue === "" ? { color: "#707070" } : null}
          >
            <option value="" disabled hidden>
              {selectPlaceholder}
            </option>
            {selectOptions.map((option) => (
              <option key={option.id} value={option.nome}>
                {option.nome}
              </option>
            ))}
          </select>
        </div>
      );
    case "submit":
      return (
        <div className="landing--submit" onClick={onFormSubmit}>
          <FontAwesomeIcon icon={faPaperPlane} />
          <span>{submitLabel}</span>
        </div>
      );
    default:
      return (
        <div className="landing--form-item">
          <input
            name={itemName}
            value={itemValue}
            onChange={itemOnChange}
            type="text"
            placeholder={`${placeholder}${required ? "*" : ""}`}
          />
        </div>
      );
  }
};

export default FormItem;
