import React from "react";

// Custom Hook
import useWindowDimensions from "../../hooks/useWindowDimensions";

// CSS
import "../../../static/css/content/financie.css";

const Financie = ({ financieURL }) => {
  const { width } = useWindowDimensions();

  let financeHeight;
  if (width > 1200) {
    financeHeight = 550;
  } else if ((width <= 1400) & (width > 501)) {
    financeHeight = 550;
  } else {
    financeHeight = (width * 15) / (width / 100) ** 1.45;
  }

  return (
    <div className="financie__container flex-c cc">
      <iframe
        title="Financiamento Coletivo"
        height={financeHeight}
        allow="geolocation"
        frameBorder="0"
        src={financieURL}
      />
    </div>
  );
};

export default Financie;
