import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

// Data
import { parlamentares, siteInfo } from "../../../config";

// Components
import Section from "../content/Section";
import Form from "../../content/Form";
import FormWidget from "../../FormWidget";

// Hooks
import ScrollToTop from "../../hooks/scrollToTop";
import Parlamentares from "../content/Parlamentares";
import FiliacaoHome from "../../content/FiliacaoHome";


const Home = () => {
  ScrollToTop();

  const sortAndFilter = (arr, type) => {
    const filteredArray = arr.filter((item) => item.type === type);

    const sortedArray = filteredArray.sort((a, b) => a.name - b.name);

    return sortedArray;
  };

  const bancadaFederal = sortAndFilter(parlamentares, "federal");
  const bancadaEstadual = sortAndFilter(parlamentares, "estadual");

  return (
    <Fragment>
      <Helmet>
        <title>PSOL/RJ | Página Inicial</title>
      </Helmet>
      <Section type="hero" heroImage="/media/img/featured/psol_lula_hero.jpg" />
      <Section title="Filie-se">
        <FiliacaoHome featuredImage="/media/img/featured/filie_se.jpg" />
      </Section>
      <Section
        title="Conheça a Bancada do PSOL/RJ no Congresso Nacional"
        wrapperStyle={{ backgroundColor: "var(--theme-primary)" }}
      >
        <Parlamentares parlamentaresObj={bancadaFederal} type="federal" />
      </Section>
      <Section
        title="Conheça nossos Deputados Estaduais na ALERJ"
        wrapperStyle={{ backgroundColor: "var(--theme-secondary)" }}
        titleColor="var(--offwhite)"
      >
        <Parlamentares parlamentaresObj={bancadaEstadual} />
      </Section>
      <Section title="Entre em Contato com o PSOL/RJ">
        <Form>
          <FormWidget
            messageField
            formName="Contato - Página Inicial"
            messageTo={siteInfo.forwardEmail}
            successMsgLine1="Obrigado por se inscrever"
            successMsgLine2=""
            useRecaptcha
            defaultForm="IK60FF4EdZBi00ApwxM9"
            defaultTracker="QlvtmvrENNQu3mYHWhgT"
          />
        </Form>
      </Section>
    </Fragment>
  );
};

export default Home;
