import React from "react";
import { Link } from "react-router-dom";

// CSS
import "../../static/css/content/filiacaohome.css";

const FiliacaoHome = ({ featuredImage }) => {
  return (
    <div className="filiacao-home__wrapper">
      <div className="filiacao-home__image">
        <img
          src={featuredImage}
          alt="Filie-se ao PSOL"
          title="Filie-se ao PSOL"
        />
      </div>
      <div className="filiacao-home__content">
        <div className="filiacao-home__text">
          Organize sua luta no PSOL! Venha compor nossa militância com a
          filiação ao partido que mais cresce no Brasil. Com sua participação,
          podemos, coletivamente, derrotar o autoritarismo e as injustiças que
          historicamente atingem o Rio de Janeiro e o Brasil. A próxima janela
          de filiação acontece em outubro, mas o formulário pode ser preenchido
          em qualquer época do ano.
        </div>
        <Link
          to="partido/filiacao"
          alt="Filie-se ao PSOL"
          title="Filie-se ao PSOL"
        >
          <div className="filiacao-home__btn"> Preencha o Formulário</div>
        </Link>
      </div>
    </div>
  );
};

export default FiliacaoHome;
