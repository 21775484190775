// Redux
import { combineReducers } from "redux";

// Firebase
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";

// Local Reducers
import { navbarReducer } from "./navbarReducer";
import { formReducer } from "./formReducer";

export default combineReducers({
  navbar: navbarReducer,
  form: formReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});
