import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Section from "../content/Section";

import ScrollToTop from "../../hooks/scrollToTop";

const Estrutura = () => {
  ScrollToTop();
  return (
    <Fragment>
      <Helmet>
        <title>PSOL RJ | Organização</title>
      </Helmet>
      <Section title="Organização" containerStyle={{ maxWidth: "75ch" }}>
        <h4
          style={{
            color: "var(--theme-secondary)",
            fontWeight: 500,
            fontSize: "1.1rem",
            maxWidth: "75ch",
            textAlign: "center",
            marginBottom: "0px",
          }}
        >
          Setorais
        </h4>
        <p>
          São espaços de mobilização, formulação e articulação constantes, para
          que as pessoas filiadas ao PSOL possam se articular em pautas
          específicas. Há setoriais estaduais, como o Setorial LGBTQIA+ e o de
          Pessoas com Deficiência, e setoriais municipais. A participação é
          aberta a todos e todas do PSOL, de forma independente ou a partir das
          tendências/correntes internas do partido.
        </p>
        <h4
          style={{
            color: "var(--theme-secondary)",
            fontWeight: 500,
            fontSize: "1.1rem",
            maxWidth: "75ch",
            textAlign: "center",
            marginBottom: "0px",
          }}
        >
          Tendências / Correntes
        </h4>
        <p>
          São organizações políticas pela construção do PSOL. As correntes e
          tendências lutam em coletivo pela construção de um partido plural, da
          esquerda socialista e sempre respeitando a democracia interna do PSOL
          e suas instâncias.
        </p>
        <p>
          Ao longo da história, correntes têm diferentes processos de formação,
          reivindicando diferentes pautas de esquerda, correntes
          internacionalistas, organização interna, teorias, etc.
        </p>
        <p>
          Durante o Congresso do PSOL, as correntes costumam apresentar teses,
          constituindo chapas para definir os rumos do partido, além de definir
          as direções.
        </p>
        <h4
          style={{
            color: "var(--theme-secondary)",
            fontWeight: 500,
            fontSize: "1.1rem",
            maxWidth: "75ch",
            textAlign: "center",
            marginBottom: "0px",
          }}
        >
          Congressos
        </h4>
        <p>
          A cada três anos, o PSOL reúne filiados e filiadas para o Congresso, a
          fim de definir a composição das diferentes instâncias de direção, além
          das defesas programáticas do partido para o próximo triênio e
          possíveis mudanças no estatuto.
        </p>
        <p>
          O processo passa pelas etapas municipais, estaduais e a nacional, com
          participação de delegados e delegadas eleitos em plenárias. Esses
          votam nas teses, chapas e resoluções apresentadas ao Congresso.
        </p>
        <p>
          As chapas apresentam essas resoluções, que deverão referenciar a
          política partidária, mas também escolhem as pessoas que deverão ocupar
          o Diretório do PSOL. Cada chapa compõe o diretório com a quantidade de
          membros proporcionalmente ao número de votos recebido.
        </p>
      </Section>
    </Fragment>
  );
};

export default Estrutura;
