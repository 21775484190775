// generalFormActions
export const FORM_SUBMIT = "FORM_SUBMIT";
export const FORM_SUCCESS = "FORM_SUCCESS";
export const FORM_FAIL = "FORM_FAIL";

// Mailer
export const SEND_FORM_EMAIL = "SEND_FORM_EMAIL";
export const SEND_FORM_EMAIL_SUCCESS = "SEND_FORM_EMAIL_SUCCESS";
export const SEND_FORM_EMAIL_FAILURE = "SEND_FORM_EMAIL_FAILURE";

// Navbar
export const COLLAPSE_NAVBAR = "COLLAPSE_NAVBAR";
