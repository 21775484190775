import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// Config
import { siteInfo } from "../../../config";

// FontAwesome Icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faCheckToSlot } from "@fortawesome/free-solid-svg-icons";

// Components
import NavbarLink from "./NavbarLink";

// Custom Hooks
import useWindowDimensions from "../../hooks/useWindowDimensions";

// CSS
import "../../../static/css/layout/navbar/navbarsmall.css";

const NavbarSmall = () => {
  const { width } = useWindowDimensions();

  return (
    <Fragment>
      <div className="navbar-sm__wrapper">
        <div className="navbar-sm__container">
          <div className="navbar-sm__logo">
            <img
              src={width >= 600 ? siteInfo.logo : siteInfo.logoSmall}
              alt={siteInfo.name}
              title={siteInfo.name}
            />
          </div>
          <div className="navbar-sm__menu">
            {width <= 400 ? (
              <Fragment>
                <Link to="/" title="Início" alt="Inícios">
                  <FontAwesomeIcon
                    icon={faHouse}
                    style={{ fontSize: "1.5rem", marginLeft: "20px" }}
                  />
                </Link>
                <Link to="/eleicoes" title="Eleições 2022" alt="Eleições 2022">
                  <FontAwesomeIcon
                    icon={faCheckToSlot}
                    style={{ fontSize: "1.7rem", marginLeft: "20px" }}
                  />
                </Link>
              </Fragment>
            ) : (
              <Fragment>
                <NavbarLink type="navigation" to="/" title="Início" />
                <NavbarLink
                  type="navigation"
                  to="/eleicoes"
                  title="Eleições 2022"
                />
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NavbarSmall;
