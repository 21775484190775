import React from "react";

// CSS
import "../../../static/css/layout/body/body.css";

const Body = ({ children }) => {
  return (
    <div className="body__wrapper">
      <div className="header__whitespace" />
      {children}
    </div>
  );
};

export default Body;
