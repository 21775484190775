import React from "react";

// Social Icons
import SocialIcon from "../../assets/SocialIcon";
import { parseParlamentarType } from "../../utils/parlamentarParser";

const Parlamentar = ({ name, type, gender, city, socials, image }) => {
  const whatsappURL = socials.whatsappURL;
  const facebookURL = socials.facebookURL;
  const instagramURL = socials.instagramURL;
  const twitterURL = socials.twitterURL;
  const tiktokURL = socials.tiktokURL;

  return (
    <div className="parlamentar__wrapper">
      <div
        className="parlamentar__picture"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
      />
      <div className="parlamentar__header">
        <div className="parlamentar__name">{name}</div>
        <div className="parlamentar__type">
          {parseParlamentarType(type, gender)}
        </div>
      </div>
      <div className="parlamentar__socials">
        {whatsappURL ? <SocialIcon type="whatsapp" url={whatsappURL} /> : null}
        {facebookURL ? <SocialIcon type="facebook" url={facebookURL} /> : null}
        {instagramURL ? (
          <SocialIcon type="instagram" url={instagramURL} />
        ) : null}
        {twitterURL ? <SocialIcon type="twitter" url={twitterURL} /> : null}
        {tiktokURL ? <SocialIcon type="tiktok" url={tiktokURL} /> : null}
      </div>
    </div>
  );
};

export default Parlamentar;
