// Firebase
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getAnalytics } from "firebase/analytics";

// Development Dependent
import { subscriptionEndpoint as developmentSubscriptionEndpoint } from "./development";
import { subscriptionEndpoint as productionSubscriptionEndpoint } from "./production";

import logo from "../static/img/logo_psol.svg";
import logoAlt from "../static/img/logo_psol_alt.svg";
import logoSmall from "../static/img/logo_psol_small.svg";

// Select Variable to Export
const selectExport = (production, development) => {
  return process.env.NODE_ENV === "production" ? production : development;
};

// Firebase Config
const firebaseConfig = {
  apiKey: "AIzaSyBkm6U-N5zEjpkRL9aQ14sv10wZETovj-Y",
  authDomain: "psol-rj.firebaseapp.com",
  projectId: "psol-rj",
  storageBucket: "psol-rj.appspot.com",
  messagingSenderId: "582433402390",
  appId: "1:582433402390:web:6c643b522a1b48dcbb4f37",
  measurementId: "G-HYW0S5REF5",
};

// Information
export const siteInfo = {
  name: "PSOL - Diretório Estadual do Rio de Janeiro",
  cnpj: "08.049.726/0001-20",
  logo: logo,
  logoAlt: logoAlt,
  logoSmall: logoSmall,
  address:
    "Rua Joaquim Silva, n° 56 - 10° Andar - Lapa, Rio de Janeiro, RJ - CEP 20.241-110",
  email: "contato@psolrj.com.br",
  forwardEmail: "comunicacaopsolrj@gmail.com",
  phone: "(21) 2224-8542",
  phoneAlt: "(21) 2224-8542",
};

export const socialInfo = {
  whatsappURL: null,
  facebookURL: "https://www.facebook.com/psolriodejaneiro",
  instagramURL: "https://www.instagram.com/psolrj/",
  twitterURL: "https://twitter.com/psolrj",
  tiktokURL: null,
};

export const parlamentares = [
  {
    id: 1,
    name: "Glauber Braga",
    type: "federal",
    gender: "m",
    city: "Nova Friburgo",
    socials: {
      whatsappURL: null,
      twitterURL: "https://twitter.com/Glauber_Braga",
      instagramURL: "https://www.instagram.com/glauberbraga_oficial/",
      facebookURL: "https://www.facebook.com/glauber.braga1",
      tiktokURL: null,
      youtubeURL: "https://www.youtube.com/c/GlauberBragaOficial",
      spotifyURL: null,
      websiteURL: "https://www.glauberbraga.com.br/",
    },
    image: "/media/img/parlamentares/glauber_braga.jpg",
  },
  {
    id: 2,
    name: "Talíria Petrone",
    type: "federal",
    gender: "f",
    city: "Niterói",
    socials: {
      whatsappURL: null,
      twitterURL: "https://twitter.com/taliriapetrone",
      instagramURL: "https://www.instagram.com/taliriapetrone/",
      facebookURL: "https://www.facebook.com/taliriapetronepsol/",
      tiktokURL: null,
      youtubeURL: "https://www.youtube.com/channel/UCvDvlGRe-b_r7AKhsRvcHSQ",
      spotifyURL: null,
      websiteURL: "https://www.taliriapetrone.com.br/",
    },
    image: "/media/img/parlamentares/taliria_petrone.jpg",
  },
  {
    id: 3,
    name: "Dani Monteiro",
    type: "estadual",
    gender: "f",
    city: "Rio de Janeiro",
    socials: {
      whatsappURL: null,
      twitterURL: "https://twitter.com/danimontpsol",
      instagramURL: "https://www.instagram.com/danimonteiro.psol/",
      facebookURL: "https://www.facebook.com/danimonteiro.psol",
      tiktokURL: "https://www.tiktok.com/@danimonteiro.psol",
      youtubeURL: null,
      spotifyURL: null,
      websiteURL: "https://danimonteiropsol.com.br/",
    },
    image: "/media/img/parlamentares/dani_monteiro.jpg",
  },
  {
    id: 4,
    name: "Flávio Serafini",
    type: "estadual",
    gender: "m",
    city: "Niterói",
    socials: {
      whatsappURL: null,
      twitterURL: "https://twitter.com/serafinipsol",
      instagramURL: "https://www.instagram.com/flavioserafini/",
      facebookURL: "https://www.facebook.com/FlavioSerafiniPSOL/",
      tiktokURL: "https://www.tiktok.com/@flavioserafinipsol",
      youtubeURL: "https://www.youtube.com/channel/UCCdzbvBREahCZKmKiwJK0xA",
      spotifyURL: "https://open.spotify.com/show/6KiXP5U0HkJvDdG88fwvtP",
      websiteURL: "https://flavioserafini.com.br/",
    },
    image: "/media/img/parlamentares/flavio_serafini.jpg",
  },
  {
    id: 5,
    name: "Mônica Francisco",
    type: "estadual",
    gender: "f",
    city: "Rio de Janeiro",
    socials: {
      whatsappURL: null,
      twitterURL: "https://twitter.com/MonicaFPsol",
      instagramURL: "https://www.instagram.com/monicasfrancisco/",
      facebookURL: "https://www.facebook.com/monicafranciscopsol/",
      tiktokURL: null,
      youtubeURL: null,
      spotifyURL: null,
      websiteURL: "https://monicafrancisco.com.br/",
    },
    image: "/media/img/parlamentares/monica_francisco.jpg",
  },
  {
    id: 6,
    name: "Renata Souza",
    type: "estadual",
    gender: "f",
    city: "Rio de Janeiro",
    socials: {
      whatsappURL:
        "https://api.whatsapp.com/send?phone=5521973956588&text=Renata%20Souza%20Deputada%20Estadual%20",
      twitterURL: "https://twitter.com/renatasouzario",
      instagramURL: "https://www.instagram.com/renatasouzario/",
      facebookURL: "https://www.facebook.com/pg/RenataSouzaPSOL",
      tiktokURL: null,
      youtubeURL: "https://www.youtube.com/channel/UC9HjU7ud0tPNTVLz-Bd3XRQ",
      spotifyURL:
        "https://open.spotify.com/show/2PMYVUuVw7o9xuRs9tpcl0?si=iz67PRIvRkuI4pIkfJY3gw&nd=1",
      websiteURL: "https://www.renatasouzapsol.com.br/",
    },
    image: "/media/img/parlamentares/renata_souza.jpg",
  },
  {
    id: 7,
    name: "Chico Alencar",
    type: "vereador",
    gender: "m",
    city: "Rio de Janeiro",
    socials: {
      whatsappURL: null,
      twitterURL: "https://twitter.com/chico_psol",
      instagramURL: "https://www.instagram.com/chico.alencar/",
      facebookURL: "https://www.facebook.com/chicoalencar/",
      tiktokURL: null,
      youtubeURL: "https://www.youtube.com/chicoalencar5050",
      websiteURL: "https://chicoalencar.com.br/",
    },
    image: "/media/img/parlamentares/chico_alencar.jpg",
  },
  {
    id: 8,
    name: "Dr. Marcos Paulo",
    type: "vereador",
    gender: "m",
    city: "Rio de Janeiro",
    socials: {
      whatsappURL: null,
      twitterURL: "https://twitter.com/drmarcospaulo_",
      instagramURL: "https://www.instagram.com/vereador_drmarcospaulo/",
      facebookURL: "https://www.facebook.com/vereadordrmarcospaulo/",
      tiktokURL: null,
      youtubeURL: null,
      websiteURL: "https://www.drmarcospaulo.com.br/",
    },
    image: "/media/img/parlamentares/marcos_paulo.jpg",
  },
  {
    id: 9,
    name: "Mônica Benício",
    type: "vereador",
    gender: "f",
    city: "Rio de Janeiro",
    socials: {
      whatsappURL: null,
      twitterURL: "https://twitter.com/monica_benicio",
      instagramURL: "https://www.instagram.com/monicaterezabenicio/",
      facebookURL: "https://www.facebook.com/monicaterezabenicio/",
      tiktokURL: null,
      youtubeURL: "https://www.youtube.com/channel/UC6qvTltN7fUmpgQeuCGv1qA",
      websiteURL: "https://monicabenicio.com.br/",
    },
    image: "/media/img/parlamentares/monica_benicio.jpg",
  },
  {
    id: 18,
    name: "Paulo Pinheiro",
    type: "vereador",
    gender: "m",
    city: "Rio de Janeiro",
    socials: {
      whatsappURL: null,
      twitterURL: null,
      instagramURL: null,
      facebookURL: null,
      tiktokURL: null,
      youtubeURL: null,
      websiteURL: null,
    },
    image: "/media/img/parlamentares/paulo_pinheiro.jpg",
  },
  {
    id: 10,
    name: "Tarcísio Motta",
    type: "vereador",
    gender: "m",
    city: "Rio de Janeiro",
    socials: {
      whatsappURL: null,
      twitterURL: "https://twitter.com/MottaTarcisio",
      instagramURL: "https://www.instagram.com/tarcisiomottapsol/",
      facebookURL: "https://www.facebook.com/TarcisioMottaPSOL",
      tiktokURL: null,
      youtubeURL: "https://www.youtube.com/c/tarcisiomottapsol",
      websiteURL: "https://tarcisiomotta.com.br/",
    },
    image: "/media/img/parlamentares/tarcisio_motta.jpg",
  },
  {
    id: 11,
    name: "Thais Ferreira",
    type: "vereador",
    gender: "f",
    city: "Rio de Janeiro",
    socials: {
      whatsappURL: null,
      twitterURL: "https://twitter.com/southaferreira",
      instagramURL: "https://www.instagram.com/sou_thaisferreira/",
      facebookURL: "https://www.facebook.com/southaisferreira",
      tiktokURL: null,
      youtubeURL: null,
      websiteURL: null,
    },
    image: "/media/img/parlamentares/thais_ferreira.jpg",
  },
  {
    id: 12,
    name: "William Siri",
    type: "vereador",
    gender: "m",
    city: "Rio de Janeiro",
    socials: {
      whatsappURL: null,
      twitterURL: "https://twitter.com/WilliamSiriRJ",
      instagramURL: "https://www.instagram.com/william_siri/",
      facebookURL: "https://www.facebook.com/WilliamSiriRJ",
      tiktokURL: null,
      youtubeURL: "https://www.youtube.com/c/WilliamSiriRJ",
      websiteURL: "https://williamsiri.com.br/",
    },
    image: "/media/img/parlamentares/william_siri.jpg",
  },
  {
    id: 14,
    name: "Benny Briolly",
    type: "vereador",
    gender: "f",
    city: "Niterói",
    socials: {
      whatsappURL: null,
      twitterURL: "https://twitter.com/BennyBriolly",
      instagramURL: "https://www.instagram.com/bennybriolly/",
      facebookURL: "https://pt-br.facebook.com/BennyBriollyPsol",
      tiktokURL: null,
      youtubeURL: null,
      websiteURL: null,
    },
    image: "/media/img/parlamentares/benny_briolly.jpg",
  },

  {
    id: 15,
    name: "Paulo Eduardo Gomes",
    type: "vereador",
    gender: "m",
    city: "Niterói",
    socials: {
      whatsappURL: 5521995165137,
      twitterURL: "https://twitter.com/PEG_PSOL",
      instagramURL: "https://www.instagram.com/pauloeduardogomes_peg/",
      facebookURL: "https://pt-br.facebook.com/pauloeduardogomes.psol",
      tiktokURL: null,
      youtubeURL: null,
      websiteURL: "http://www.pauloeduardo.org/",
    },
    image: "/media/img/parlamentares/paulo_eduardo_gomes.jpg",
  },
  {
    id: 13,
    name: "Professor Túlio",
    type: "vereador",
    gender: "m",
    city: "Niterói",
    socials: {
      whatsappURL:
        "https://api.whatsapp.com/send/?phone=5521976938463&text&type=phone_number&app_absent=0",
      twitterURL: "https://twitter.com/proftulionit",
      instagramURL: "https://www.instagram.com/professortulio/",
      facebookURL: "https://www.facebook.com/professortulio/",
      tiktokURL: null,
      youtubeURL: "https://www.youtube.com/channel/UCquet3mIpKuJcg0NIAkL27Q",
      websiteURL: "https://professortulio.com.br/",
    },
    image: "/media/img/parlamentares/professor_tulio.jpg",
  },

  {
    id: 16,
    name: "Yuri Moura",
    type: "vereador",
    gender: "m",
    city: "Petrópolis",
    socials: {
      whatsappURL: null,
      twitterURL: "https://twitter.com/yurimourarj",
      instagramURL: "https://www.instagram.com/yurimourarj/",
      facebookURL: "https://www.facebook.com/yurimourapetropolis",
      tiktokURL: null,
      youtubeURL: "https://www.youtube.com/channel/UCowoVvyQqnhgHbQOdc6QFsA",
      websiteURL: "https://www.yurimoura.com.br/",
    },
    image: "/media/img/parlamentares/yuri_moura.jpg",
  },
  {
    id: 17,
    name: "Professor Josemar",
    type: "vereador",
    gender: "m",
    city: "São Gonçalo",
    socials: {
      whatsappURL: null,
      twitterURL: "https://twitter.com/profjosemarpsol",
      instagramURL: "https://www.instagram.com/profjosemarpsol",
      facebookURL: "https://www.facebook.com/profjosemarpsol",
      tiktokURL: null,
      youtubeURL: "https://www.youtube.com/channel/UCX6_I9xMMqpR_a-4jr5KtEA",
      websiteURL: "https://profjosemarpsol.com.br/",
    },
    image: "/media/img/parlamentares/professor_josemar.jpg",
  },
];

export const candidatos = [
  {
    id: 1,
    name: "William Siri",
    gender: "m",
    type: "federal",
    cnpj: "47.369.025/0001-20",
    faixa: "recursos",
    number: 5022,
    email: "falecomsiri@gmail.com",
    domain: null,
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: "https://twitter.com/siri_william",
      instagramURL: "https://www.instagram.com/william_siri/",
      facebookURL: "https://facebook.com/williamsiri50",
      youtubeURL: null,
      websiteURL: "https://williamsiri.com.br/",
      whatsappURL: null,
    },
  },
  {
    id: 2,
    name: "Mãedata Thais Ferreira",
    gender: "f",
    type: "federal",
    cnpj: "47.369.003/0001-60",
    faixa: "recursos",
    number: 5010,
    email: "maedata.federal@gmail.com",
    domain: null,
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: "https://twitter.com/southaferreira",
      instagramURL: "https://www.instagram.com/sou_thaisferreira/",
      facebookURL: "https://facebook.com/southaisferreira",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 3,
    name: "Tarcisio Motta",
    gender: "m",
    type: "federal",
    cnpj: "47.368.930/0001-66",
    faixa: "recursos",
    number: 5000,
    email: "contato@tarcisiomotta.com.br",
    domain: null,
    featuredVideoId: null,
    featuredFinancing: "https://tarcisio-motta.financie.de/widget",
    socials: {
      twitterURL: "https://twitter.com/MottaTarcisio",
      instagramURL: "https://www.instagram.com/tarcisiomottapsol/",
      facebookURL: "https://www.facebook.com/TarcisioMottaPSOL",
      youtubeURL: "https://www.youtube.com/c/tarcisiomottapsol",
      websiteURL: "https://tarcisiomotta.com.br",
      whatsappURL: null,
    },
  },
  {
    id: 4,
    name: "Talíria Petrone",
    gender: "f",
    type: "federal",
    cnpj: "47.369.001/0001-71",
    faixa: "recursos",
    number: 5077,
    email: "agendataliriapetrone@gmail.com",
    domain: null,
    featuredVideoId: "iYfjdh4efYA",
    featuredFinancing: "https://taliriapetrone.financie.de/widget",
    socials: {
      twitterURL: "https://twitter.com/taliriapetrone",
      instagramURL: "https://www.instagram.com/taliriapetrone/",
      facebookURL: "https://www.facebook.com/taliriapetronepsol",
      youtubeURL: null,
      websiteURL: "https://www.taliriapetrone.com.br/",
      whatsappURL: null,
    },
  },
  {
    id: 6,
    name: "Juliana Drumond",
    gender: "f",
    type: "federal",
    cnpj: "47.369.000/0001-27",
    faixa: "recursos",
    number: 5013,
    email: "judrumondpsol@gmail.com",
    domain: null,
    featuredVideoId: null,
    featuredFinancing: "https://julianadrumond.financie.de/widget",
    socials: {
      twitterURL: "http://twitter.com/JuDrumond50",
      instagramURL: "http://instagram.com/drumondjuliana",
      facebookURL: "http://facebook.com/drumondjuliana",
      youtubeURL: "https://youtube.com/channel/UCLV8BuCBD_wSaW0AbGPcpHg",
      websiteURL: "https://linktr.ee/judrumond",
      whatsappURL: null,
    },
  },
  {
    id: 7,
    name: "Babá",
    gender: "m",
    type: "federal",
    cnpj: "47.368.945/0001-24",
    faixa: "basica",
    number: 5051,
    email: "babadeluta@gmail.com",
    domain: "https://baba5051.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/babapsol/",
      facebookURL: "https://www.facebook.com/babapsol",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 8,
    name: "Pastor Henrique Vieira",
    gender: "m",
    type: "federal",
    cnpj: "47.369.009/0001-38",
    faixa: "recursos",
    number: 5033,
    email: null,
    domain: null,
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: "https://twitter.com/pastorhenriquev/",
      instagramURL:
        "https://instagram.com/pastorhenriquevieira?igshid=YmMyMTA2M2Y=",
      facebookURL: "https://www.facebook.com/pastorhenriquevieira/",
      youtubeURL: "https://youtube.com/c/PastorHenriqueVieira",
      websiteURL: "https://pastorhenriquevieira.com.br",
      whatsappURL: null,
    },
  },
  {
    id: 9,
    name: "Glauber",
    gender: "m",
    type: "federal",
    cnpj: "47.368.989/0001-54",
    faixa: "recursos",
    number: 5080,
    email: "contato@glauber5080.com.br",
    domain: null,
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/glauberbraga_oficial/",
      facebookURL: "https://www.facebook.com/glauber.braga1",
      youtubeURL: "https://youtube.com/c/GlauberBragaOficial",
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 10,
    name: "Chico Alencar",
    gender: "m",
    type: "federal",
    cnpj: "47.369.051/0001-59",
    faixa: "recursos",
    number: 5050,
    email: "comunicacao@chicoalencar.com.br",
    domain: null,
    featuredVideoId: "zCnksT-qwSk",
    featuredFinancing: null,
    queroMaterial: "https://queromaterial.chicoalencar.com.br",
    socials: {
      twitterURL: "https://twitter.com/chico_psol",
      instagramURL: "https://instagram.com/chico.alencar?igshid=YmMyMTA2M2Y=",
      facebookURL: "https://www.facebook.com/chicoalencar",
      youtubeURL: "https://youtube.com/c/ChicoAlencarOficial",
      websiteURL: "https://chicoalencar.com.br",
      whatsappURL: null,
    },
  },
  {
    id: 11,
    name: "Derê",
    gender: "m",
    type: "federal",
    cnpj: "47.369.011/0001-07",
    faixa: "recursos",
    number: 5007,
    email: "deregomesrio@gmail.com",
    domain: null,
    featuredVideoId: "a6Hbya5zmR0",
    featuredFinancing: "https://deregomes.financie.de/widget",
    socials: {
      twitterURL: "https://www.twitter.com/deregomesrio",
      instagramURL: "https://www.instagram.com/deregomesrio/",
      facebookURL: "https://www.facebook.com/deregomesrio",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 12,
    name: "André Barros",
    gender: "m",
    type: "federal",
    cnpj: "47.369.294/0001-97",
    faixa: "recursos",
    number: 5042,
    email: "advandrebarros@gmail.com",
    domain: null,
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/advogadoandrebarros/",
      facebookURL: "https://www.facebook.com/advogadoandrebarros",
      youtubeURL: "https://www.youtube.com/c/TVANDR%C3%89BARROS",
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 13,
    name: "Yuri",
    gender: "m",
    type: "estadual",
    cnpj: "47.369.013/0001-04",
    faixa: "recursos",
    number: 50700,
    email: "campanhayuri2022@gmail.com",
    domain: null,
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: "https://twitter.com/yurimourarj",
      instagramURL: "https://www.instagram.com/yurimourarj/",
      facebookURL: "https://www.facebook.com/yurimourapetropolis",
      youtubeURL: "http://youtube.com/c/CanalDoYuri50",
      websiteURL: "https://yurimoura.com",
      whatsappURL: null,
    },
  },
  {
    id: 14,
    name: "Tatianny da Coletiva Feminista",
    gender: "f",
    type: "estadual",
    cnpj: "47.368.974/0001-96",
    faixa: "recursos",
    number: 50111,
    email: "coletivafeministadopsol@gmail.com",
    domain: null,
    featuredVideoId: null,
    featuredFinancing:
      "https://coletiva-feminista-do-psol-rj.financie.de/widget",
    socials: {
      twitterURL: "https://twitter.com/tatyaraujoPSOL",
      instagramURL: "https://www.instagram.com/tatiannyaraujopsol/",
      facebookURL: "https://www.facebook.com/TyRosa.Kahlo",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 15,
    name: "Rose Cipriano e Coletiva Periférica",
    gender: "f",
    type: "estadual",
    cnpj: "47.368.947/0001-13",
    faixa: "recursos",
    number: 50800,
    email: "contatocoletivodasperiferias@gmail.com",
    domain: null,
    featuredVideoId: null,
    featuredFinancing:
      "https://rose-cipriano-coletivo-periferias.financie.de/widget",
    socials: {
      twitterURL: "https://twitter.com/rosecipriano_",
      instagramURL: "https://www.instagram.com/professorarosecipriano/",
      facebookURL: "https://www.facebook.com/rose.cipriano.96",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 16,
    name: "Renata Souza",
    gender: "f",
    type: "estadual",
    cnpj: "47.368.996/0001-56",
    faixa: "recursos",
    number: 50007,
    email: "renatasouzacampanha2022@gmail.com",
    domain: null,
    featuredVideoId: "JT4Ld5uWqtA",
    featuredFinancing: "https://renatasouza.financie.de/widget",
    socials: {
      twitterURL: "https://twitter.com/renatasouzario",
      instagramURL: "https://www.instagram.com/renatasouzario/",
      facebookURL: "https://facebook.com/RenataSouzaPSOL",
      youtubeURL: "https://youtube.com/c/renatasouzario",
      websiteURL: "https://renatasouzapsol.com.br",
      whatsappURL: null,
    },
  },
  {
    id: 17,
    name: "Paulo Eduardo Gomes",
    gender: "m",
    type: "estadual",
    cnpj: "47.369.063/0001-83",
    faixa: "recursos",
    number: 50001,
    email: "mandato@pauloeduardo.org",
    domain: null,
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://instagram.com/pauloeduardogomes_peg",
      facebookURL: "https://www.facebook.com/pauloeduardogomes.psol",
      youtubeURL: null,
      websiteURL: "https://www.pauloeduardo.org/site/",
      whatsappURL: null,
    },
  },
  {
    id: 18,
    name: "Mônica Francisco",
    gender: "f",
    type: "estadual",
    cnpj: "47.368.917/0001-07",
    faixa: "recursos",
    number: 50888,
    email: "comunicacaomonicafrancisco@gmail.com",
    domain: null,
    featuredVideoId: "b35qkod--yc",
    featuredFinancing: "https://monica-francisco.financie.de/widget",
    socials: {
      twitterURL: "https://twitter.com/monicafpsol",
      instagramURL: "https://instagram.com/monicasfrancisco",
      facebookURL: "https://www.facebook.com/monicafranciscopsol",
      youtubeURL: "https://youtube.com/c/M%C3%B4nicaFranciscopsol",
      websiteURL: "https://www.monicafrancisco.com.br",
      whatsappURL: null,
    },
  },
  {
    id: 19,
    name: "Dr. Marcos Paulo",
    gender: "m",
    type: "estadual",
    cnpj: "47.368.960/0001-72",
    faixa: "recursos",
    number: 50000,
    email: "marcospaulo.marcospaulo@gmail.com",
    domain: null,
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/vereador_drmarcospaulo/",
      facebookURL: "https://www.facebook.com/vereadordrmarcospaulo",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 20,
    name: "Professor Josemar",
    gender: "m",
    type: "estadual",
    cnpj: "47.368.971/0001-52",
    faixa: "recursos",
    number: 50500,
    email: "contatoprofjosemar@gmail.com",
    domain: null,
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: "https://twitter.com/profjosemarpsol",
      instagramURL: "https://www.instagram.com/profjosemarpsol/",
      facebookURL: "https://www.facebook.com/profjosemarpsol",
      youtubeURL: null,
      websiteURL: "https://profjosemarpsol.com.br/",
      whatsappURL: null,
    },
  },
  {
    id: 21,
    name: "Flavio Serafini",
    gender: "m",
    type: "estadual",
    cnpj: "47.368.983/0001-87",
    faixa: "recursos",
    number: 50123,
    email: "contato@flavioserafini.com.br",
    domain: null,
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: "https://twitter.com/serafinipsol",
      instagramURL: "https://instagram.com/flavioserafini",
      facebookURL: "https://www.facebook.com/FlavioSerafiniPSOL",
      youtubeURL: null,
      websiteURL: "https://flavioserafini.com.br/",
      whatsappURL: null,
    },
  },
  {
    id: 22,
    name: "Dani Monteiro",
    gender: "f",
    type: "estadual",
    cnpj: "47.369.017/0001-84",
    faixa: "recursos",
    number: 50050,
    email: "mandato@danimonteiropsol.com.br",
    domain: null,
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/danimonteiro.psol/",
      facebookURL: "https://www.facebook.com/danimonteiro.psol",
      youtubeURL: null,
      websiteURL: "https://danimonteiropsol.com.br",
      whatsappURL: null,
    },
  },
  {
    id: 23,
    name: "Gari Bruno da Rosa",
    gender: "m",
    type: "estadual",
    cnpj: "47.369.006/0001-02",
    faixa: "recursos",
    number: 50300,
    email: "brunogari972@gmail.com",
    domain: null,
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/brunodarosagari/",
      facebookURL: "https://www.facebook.com/brunodarosagari",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 24,
    name: "Benny Briolly",
    gender: "f",
    type: "estadual",
    cnpj: "47.369.016/0001-30",
    faixa: "recursos",
    number: 50555,
    email: "bennybriollydeputrava@gmail.com",
    domain: null,
    featuredVideoId: null,
    featuredFinancing: "https://benny-briolly.financie.de/widget",
    socials: {
      twitterURL: null,
      instagramURL: "https://instagram.com/bennybriolly",
      facebookURL: "https://www.facebook.com/BennyBriolly",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 25,
    name: "Roberta Barcelos",
    gender: "f",
    type: "federal",
    cnpj: "47.369.030/0001-33",
    faixa: "basica",
    number: 5004,
    email: "ro-carolinna@hotmail.com",
    domain: "robertabarcelos5004.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://instagram.com/bxrcxllxs",
      facebookURL: "https://www.facebook.com/Bela.Adormecida.da.Favela",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 26,
    name: "Alex Campanhão",
    gender: "m",
    type: "federal",
    cnpj: "47.368.963/0001-06",
    faixa: "basico",
    number: 5071,
    email: "alexcampanhao50@gmail.com",
    domain: "alexcampanhao5071.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/alexcampanhao/",
      facebookURL: "https://www.facebook.com/alex.campanhao",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 27,
    name: "Andrea Cassa",
    gender: "f",
    type: "federal",
    cnpj: "47.369.287/0001-95",
    faixa: "basico",
    number: 5023,
    email: "andreacassa19@gmail.com",
    domain: "andreacassa5023.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: "https://twitter.com/andrea_cassa",
      instagramURL: "https://www.instagram.com/andrea_cassa_50/",
      facebookURL: "https://www.facebook.com/andreacassapsol",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 28,
    name: "Denilson Prata",
    gender: "m",
    type: "federal",
    cnpj: "08.049.726/0001-20",
    faixa: "basico",
    number: 5055,
    email: "denilsonprataadv@gmail.com",
    domain: "denilsonprata5055.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/denilsonpratadasilva/",
      facebookURL: "https://www.facebook.com/denilson.pratadasilva",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 29,
    name: "Eliel Jr Ocupa Baixada",
    gender: "m",
    type: "federal",
    cnpj: "47.368.941/0001-46",
    faixa: "basico",
    number: 5060,
    email: "eliellimajr@gmail.com",
    domain: "elieljrocupabaixada5060.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: "https://twitter.com/Eliel_JrEF",
      instagramURL: "https://www.instagram.com/oelieljunior/",
      facebookURL: "https://www.facebook.com/oelieljunior",
      youtubeURL: "https://www.youtube.com/channel/UCzKKCaXg-zQy4n_SW1Wy2wg",
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 30,
    name: "Fabio Baraúna",
    gender: "m",
    type: "federal",
    cnpj: "47.368.992/0001-78",
    faixa: "basico",
    number: 5089,
    email: "fabiodossantosdcx@hotmail.com",
    domain: "fabiobarauna5089.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/fabiobaraunadcx/",
      facebookURL: "https://www.facebook.com/fabiobaraunadcx",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 31,
    name: "Gilberto Vanderlei",
    gender: "m",
    type: "federal",
    cnpj: "47.368.990/0001-89",
    faixa: "basico",
    number: 5025,
    email: "mecanicanovawgd335@gmail.com",
    domain: "gilbertovanderlei5025.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/deputadogilbertovanderlei_/",
      facebookURL: "https://www.facebook.com/gilberto.vanderlei.378",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 32,
    name: "Joel Santos",
    gender: "m",
    type: "federal",
    cnpj: "47.368.986/0001-10",
    faixa: "basico",
    number: 5030,
    email: "joelsantos1402@gmail.com",
    domain: "joelsantos5030.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: null,
      facebookURL: null,
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 33,
    name: "Kabeça Borracheiro",
    gender: "m",
    type: "federal",
    cnpj: "47.368.951/0001-81",
    faixa: "basico",
    number: 5021,
    email: "adilsoncunha956@gmail.com",
    domain: "kabecaborracheiro5021.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: null,
      facebookURL: null,
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 34,
    name: "Lulu Araujo",
    gender: "f",
    type: "federal",
    cnpj: "47.368.927/0001-42",
    faixa: "basico",
    number: 5002,
    email: "lucianaaraujo21.lu@gmail.com",
    domain: "luluaraujo5002.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: "https://twitter.com/Lu_poderpopular",
      instagramURL: "https://www.instagram.com/luciana.asp21/",
      facebookURL: "https://www.facebook.com/lucianapoderpopular",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 35,
    name: "MAMC",
    gender: "m",
    type: "federal",
    cnpj: "47.369.108/0001-10",
    faixa: "basico",
    number: 5088,
    email: "marcoamcoutinho@gmail.com",
    domain: "mamc5088.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/mamc_88",
      facebookURL: "https://www.facebook.com/marcoantonio.monteirocoutinho",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 36,
    name: "Miriana Amaral",
    gender: "f",
    type: "federal",
    cnpj: "47.369.272/0001-27",
    faixa: "basico",
    number: 5020,
    email: "advmirianaamaral@gmail.com",
    domain: "mirianaamaral5020.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/dramirianaamaral/",
      facebookURL: "https://www.facebook.com/dramirianaamaral",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 37,
    name: "Professora Natália Soares",
    gender: "f",
    type: "federal",
    cnpj: "47.369.005/0001-50",
    faixa: "recursos",
    number: 5005,
    email: "profnatalia50@gmail.com",
    domain: null,
    featuredVideoId: "eO4DiQD-CRQ",
    featuredFinancing: "https://professora-natalia.financie.de/widget",
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/profnataliasoares/",
      facebookURL: "https://www.facebook.com/nataliasoarespsol",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 38,
    name: "Professor Fabiano",
    gender: "m",
    type: "federal",
    cnpj: "47.368.969/0001-83",
    faixa: "basico",
    number: 5029,
    email: "fsouzavieira@hotmail.com",
    domain: "professorfabiano5029.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/proffabianosouza/",
      facebookURL:
        "https://www.facebook.com/profile.php?id=100057866269212&show_switched_toast=true",
      youtubeURL: "https://www.youtube.com/channel/UC3KsRxFl0QAdZhHEE5PAtdQ",
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 39,
    name: "Professor Maximo Lustosa",
    gender: "m",
    type: "federal",
    cnpj: "47.368.911/0001-30",
    faixa: "basico",
    number: 5012,
    email: "maxheleno@gmail.com",
    domain: "professormaximolustosa5012.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: "https://twitter.com/maxheleno",
      instagramURL: "https://www.instagram.com/profmaximolustosa/",
      facebookURL: "https://www.facebook.com/maximo.lustosa",
      youtubeURL: "https://www.youtube.com/c/M%C3%A1ximoLustosa",
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 40,
    name: "Regina Fonte",
    gender: "f",
    type: "federal",
    cnpj: "47.369.221/0001-03",
    faixa: "basico",
    number: 5027,
    email: "reginalsfonte@gmail.com",
    domain: "reginafonte5027.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/fonteregina",
      facebookURL: "https://www.facebook.com/reginalucia.fonte",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 41,
    name: "Alexandre Fonseca",
    gender: "m",
    type: "estadual",
    cnpj: "47.369.014/0001-40",
    faixa: "basico",
    number: 50333,
    email: "alexandrecampos@id.uff.br",
    domain: "alexandrefonseca50333.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/alexandrecif/",
      facebookURL: null,
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 42,
    name: "Alexandre Pantaleão",
    gender: "m",
    type: "estadual",
    cnpj: "47.368.920/0001-20",
    faixa: "basico",
    number: 50884,
    email: "operariopantaleao@yahoo.com.br",
    domain: "alexandrepantaleao50884.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/alexandre_pantaleao_/",
      facebookURL: "https://www.facebook.com/alexandre.pantaleao.1238",
      youtubeURL: "https://www.youtube.com/c/AlexandrePantale%C3%A3o",
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 43,
    name: "Arildo Junior",
    gender: "m",
    type: "estadual",
    cnpj: "47.369.002/0001-16",
    faixa: "basico",
    number: 50200,
    email: "arildonerysjunior@gmail.com",
    domain: "arildojunior50200.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: "https://twitter.com/ArildoNerys",
      instagramURL: "https://www.instagram.com/arildonerysjunior/",
      facebookURL: "https://www.facebook.com/arildonerys.junior",
      youtubeURL: "https://www.youtube.com/channel/UCviG9T8l0uw0U2QY-vBl_cA",
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 44,
    name: "Bernarda Thailania",
    gender: "f",
    type: "estadual",
    cnpj: "47.369.208/0001-46",
    faixa: "basico",
    number: 50008,
    email: "bernardathailaniapsol@gmail.com",
    domain: "bernardathailania50008.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/bthailania/",
      facebookURL: "https://www.facebook.com/Thailania",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 45,
    name: "Candido Sousa",
    gender: "m",
    type: "estadual",
    cnpj: "47.368.961/0001-17",
    faixa: "basico",
    number: 50097,
    email: "morehcandido@gmail.com",
    domain: "candidosouza50097.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: null,
      facebookURL: null,
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 46,
    name: "Celena Santos",
    gender: "f",
    type: "estadual",
    cnpj: "47.369.322/0001-76",
    faixa: "basico",
    number: 50013,
    email: "celenasantos9404@gmail.com",
    domain: "celenasantos50013.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/celenasantosoficial/",
      facebookURL: "https://www.facebook.com/celenasantosqueimados",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 47,
    name: "Gustavo Bueno",
    gender: "m",
    type: "estadual",
    cnpj: null,
    faixa: "basico",
    number: 50005,
    email: "gbuenorj@gmail.com",
    domain: "gustavobueno50005.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: "https://twitter.com/GBuenoRJ",
      instagramURL: "https://www.instagram.com/gbuenorj/",
      facebookURL: "https://www.facebook.com/gbuenorj",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 48,
    name: "Jefferson Vinco",
    gender: "m",
    type: "estadual",
    cnpj: "47.369.022/0001-97",
    faixa: "basico",
    number: 50222,
    email: "comunicacaojeffesonvinco@gmail.com ",
    domain: "jeffersonvinco50222.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: "https://twitter.com/JeffersonVinco",
      instagramURL: "https://www.instagram.com/jeffersonvinco/",
      facebookURL: "https://www.facebook.com/jefferson.vinco/",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 49,
    name: "Leonardo Leal",
    gender: "m",
    type: "estadual",
    cnpj: "47.368.976/0001-85",
    faixa: "basico",
    number: 50656,
    email: "leonardoleal.rj@hotmail.com",
    domain: "leonardoleal50656.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/leonardoleal.rj/",
      facebookURL: null,
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 50,
    name: "Marcos Zamenhof",
    gender: "m",
    type: "estadual",
    cnpj: "47.369.184/0001-25",
    faixa: "basico",
    number: 50362,
    email: null,
    domain: "marcoszamenhof50362.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/marcoszamenhof/",
      facebookURL: "https://www.facebook.com/marcos.zamenhof",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 51,
    name: "Maria Bertoche",
    gender: "f",
    type: "estadual",
    cnpj: "47.368.944/0001-80",
    faixa: "basico",
    number: 50021,
    email: "campanhamariabertoche@gmail.com",
    domain: "mariabertoche50021.com.br",
    featuredVideoId: null,
    featuredFinancing: "https://maria-bertoche.financie.de/widget",
    socials: {
      twitterURL: "https://twitter.com/mariabertoche",
      instagramURL: "https://www.instagram.com/mariabertoche/",
      facebookURL: "https://www.facebook.com/mariabertoche",
      youtubeURL: "https://www.youtube.com/channel/UC4MEdVt-8RMQxJMNiz93zYA",
      websiteURL: "www.mariabertoche.com.br",
      whatsappURL: null,
    },
  },
  {
    id: 52,
    name: "Naide Ribeiro",
    gender: "m",
    type: "estadual",
    cnpj: "47.369.167/0001-98",
    faixa: "basico",
    number: 50056,
    email: "naideribeiro@gmail.com",
    domain: "naideribeiro50056.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/juniornaide/",
      facebookURL: "https://www.facebook.com/naide.junior",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 53,
    name: "Pedro Henrique Correa",
    gender: "m",
    type: "estadual",
    cnpj: "47.368.999/0001-90",
    faixa: "basico",
    number: 50917,
    email: "pedrocorreapsi@gmail.com",
    domain: "pedrohenriquecorrea50917.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/pedrohenriquecorrea/",
      facebookURL: null,
      youtubeURL: "https://www.youtube.com/c/Cr%C3%ADticaComunista",
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 54,
    name: "Professor Wenderson Dias",
    gender: "m",
    type: "estadual",
    cnpj: "47.368.942/0001-90",
    faixa: "basico",
    number: 50350,
    email: "wendersondiasribeiro@yahoo.com.br",
    domain: "professorwendersondias50350.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/profwenderson/",
      facebookURL: "https://www.facebook.com/wenderson.dias.75",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 55,
    name: "Protetora Patricia Vargas",
    gender: "f",
    type: "estadual",
    cnpj: "47.368.968/0001-39",
    faixa: "basico",
    number: 50100,
    email: "triciamvargas@yahoo.com.br",
    domain: "protetorapatriciavargas.50100.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/protetora.patriciavargas/",
      facebookURL: "https://www.facebook.com/protetorapatriciavargas",
      youtubeURL:
        "https://www.youtube.com/channel/UCaAhv6oVzG5b8XKJUNbUfcA?app=desktop",
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 57,
    name: "Serginho Monteiro",
    gender: "m",
    type: "estadual",
    cnpj: "47.368.935/0001-99",
    faixa: "basico",
    number: 50420,
    email: "sergiomonteiro.ambiental@gmail.com",
    domain: "serginhomonteiro50420.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/serginhomonteiro.rj/",
      facebookURL: "https://www.facebook.com/profile.php?id=100007371735989",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 58,
    name: "Sergio Paes",
    gender: "m",
    type: "estadual",
    cnpj: "47.369.218/0001-81",
    faixa: "basico",
    number: 50016,
    email: "ginho_ii@yahoo.com.br",
    domain: "sergiopaes50016.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: null,
      facebookURL: null,
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 59,
    name: "Thiago Orgânico",
    gender: "m",
    type: "estadual",
    cnpj: "47.368.991/0001-23",
    faixa: "basico",
    number: 50444,
    email: "chale.organico@gmail.com",
    domain: "thiagoorganico50444.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: "https://twitter.com/thiagoorganico",
      instagramURL: "https://www.instagram.com/thiago_organico/",
      facebookURL: "https://www.facebook.com/thiagosilva.macae",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 60,
    name: "Yaritza",
    gender: "f",
    type: "estadual",
    cnpj: "47.368.981/0001-98",
    faixa: "basico",
    number: 50069,
    email: "dadumas@gmail.com",
    domain: "yaritza50069.com.br",
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: null,
      instagramURL: "https://www.instagram.com/yaritza_psol50/",
      facebookURL: null,
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
  {
    id: 61,
    name: "Ivanete",
    gender: "f",
    type: "federal",
    cnpj: null,
    faixa: "recursos",
    number: 5057,
    email: null,
    domain: null,
    featuredVideoId: null,
    featuredFinancing: null,
    socials: {
      twitterURL: "https://twitter.com/IvaneteSilvaRJ",
      instagramURL: "https://www.instagram.com/ivanetesilvarj/",
      facebookURL: "https://www.facebook.com/ivanetesilvapsol",
      youtubeURL: null,
      websiteURL: null,
      whatsappURL: null,
    },
  },
];

// Firebase Exports
export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseFirestore = firebaseApp.firestore();
export const firebaseAnalytics = getAnalytics(firebaseApp);

// Environment
export const subscriptionEndpoint = selectExport(
  productionSubscriptionEndpoint,
  developmentSubscriptionEndpoint
);

export const mailerEndpoint =
  "https://us-central1-psol-rj.cloudfunctions.net/formResponseMailer";

export const mailerFiliaEndpoint =
  "https://us-central1-psol-rj.cloudfunctions.net/filiacaoMailer";
