import React from "react";

// FontAwesome Icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faFacebook,
  faInstagram,
  faTwitter,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

// CSS
import "../../static/css/assets/social-icon.css";

const SocialIcon = ({ type, url }) => {
  switch (type) {
    case "whatsapp":
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          alt="Whatsapp"
          title="Whatsapp"
        >
          <div className="social-icon__wrapper">
            <FontAwesomeIcon icon={faWhatsapp} />
          </div>
        </a>
      );
    case "facebook":
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          alt="Facebook"
          title="Facebook"
        >
          <div className="social-icon__wrapper">
            <FontAwesomeIcon icon={faFacebook} />
          </div>
        </a>
      );
    case "instagram":
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          alt="Instagram"
          title="Instagram"
        >
          <div className="social-icon__wrapper">
            <FontAwesomeIcon icon={faInstagram} />
          </div>
        </a>
      );
    case "twitter":
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          alt="Twitter"
          title="Twitter"
        >
          <div className="social-icon__wrapper">
            <FontAwesomeIcon icon={faTwitter} />
          </div>
        </a>
      );
    case "tiktok":
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          alt="TikTok"
          title="TikTok"
        >
          <div className="social-icon__wrapper">
            <FontAwesomeIcon icon={faTiktok} />
          </div>
        </a>
      );
    default:
      return null;
  }
};

export default SocialIcon;
