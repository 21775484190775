import React from "react";

// Router
import { HashLink } from "react-router-hash-link";

// CSS
import "../../../static/css/layout/footer/footerlink.css"

const FooterLink = ({ type, to, title, featured = false }) => {
  switch (type) {
    case "external":
      return (
        <a
          className={featured ? "footerlink__a footerlink__featured" : "footerlink__a"}
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          title={title}
          alt={title}
        >
          <div className="footerlink__wrapper">{title}</div>
          <div className="footerlink__underscore"></div>
        </a>
      );
    default:
      return (
        <HashLink
          className={featured ? "footerlink__a footerlink__featured" : "footerlink__a"}
          title={title}
          alt={title}
          to={to}
        >
          <div className="footerlink__wrapper">{title}</div>
          <div className="footerlink__underscore"></div>
        </HashLink>
      );
  }
};

export default FooterLink;
