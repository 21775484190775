import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Section from "../content/Section";

import ScrollToTop from "../../hooks/scrollToTop";

const QuemSomos = () => {
  ScrollToTop();
  return (
    <Fragment>
      <Helmet>
        <title>PSOL RJ | Quem Somos</title>
      </Helmet>
      <Section title="Quem Somos" containerStyle={{ maxWidth: "75ch" }}>
        <h4
          style={{
            color: "var(--theme-secondary)",
            fontWeight: 500,
            fontSize: "1.1rem",
            maxWidth: "75ch",
            textAlign: "center",
            marginBottom: "0px",
          }}
        >
          O que é o PSOL?
        </h4>
        <p>
          O Partido Socialismo e Liberdade é o maior representante da esquerda
          no estado do Rio de Janeiro. Constantemente apontado como “necessário”
          e “diferente”, o PSOL contempla setores da sociedade que se organizam
          para transformar as realidades.
        </p>
        <p>
          Como é “na luta que a gente se encontra”, o PSOL vai além das disputas
          eleitorais, oferecendo espaços de formação política para construir as
          bases das lutas contra toda forma de opressão.
        </p>
        <p>
          Com o Socialismo como Sul e a Liberdade enquanto princípio, sempre
          teremos lado: o da dignidade humana, contra a destruição da natureza,
          contra a fome e a miséria, contra qualquer tipo de injustiça, em
          defesa da saúde, da educação e da diversidade.
        </p>
        <h4
          style={{
            color: "var(--theme-secondary)",
            fontWeight: 500,
            fontSize: "1.1rem",
            maxWidth: "75ch",
            textAlign: "center",
            marginBottom: "0px",
          }}
        >
          História
        </h4>
        <p>
          O PSOL surgiu em 2004, fruto de grupos que romperam com o PT quando
          esse patrocinou uma Reforma da Previdência que retirava direitos da
          classe trabalhadora. Daí nasceu a possibilidade de construir um novo
          jeito de fazer política.
        </p>
        <p>
          Com independência política e coerência, o PSOL tem se comprometido com
          as lutas sociais e a garantia dos direitos democráticos, sem nenhuma
          ligação com financiamento empresarial ou negociatas. O lucro não deve
          estar acima da vida!
        </p>
      </Section>
    </Fragment>
  );
};

export default QuemSomos;
