export const parseParlamentarType = (type, gender) => {
  if (gender === "m") {
    switch (type) {
      case "federal":
        return "Deputado Federal";
      case "estadual":
        return "Deputado Estadual";
      case "vereador":
        return "Vereador";
      default:
        return "";
    }
  } else {
    switch (type) {
      case "federal":
        return "Deputada Federal";
      case "estadual":
        return "Deputada Estadual";
      case "vereador":
        return "Vereadora";
      default:
        return "";
    }
  }
};
