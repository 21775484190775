import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";

// Config
import { siteInfo } from "../../config";

// Layout

import NavbarSmall from "./navbar/NavbarSmall";

import Body from "./body/Body";

import SubFooter from "./footer/SubFooter";

// CSS
import "../../static/css/public/main.css";
import "../../static/css/public/vars.css";

const Layout = () => {
  return (
    <Fragment>
      <NavbarSmall />
      <Body>
        <div style={{ minHeight: "60px" }}></div>
        <Outlet />
      </Body>
      <SubFooter legalContent={`${siteInfo.name} | CNPJ: ${siteInfo.cnpj}`} />
    </Fragment>
  );
};

export default Layout;
