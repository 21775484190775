import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

// Data
import { parlamentares } from "../../config";

// Components
import Section from "./content/Section";
import Parlamentares from "./content/Parlamentares";
import Parlamentar from "./content/Parlamentar";
import BioParlamentar from "./content/BioParlamentar";

// Hooks
import ScrollToTop from "../hooks/scrollToTop";

// CSS
import "../../static/css/layout/layoutbancada.css";
import { HashLink } from "react-router-hash-link";

const LayoutBancada = ({ type }) => {
  ScrollToTop();
  const sortAndFilter = (arr, type) => {
    const filteredArray = arr.filter((item) => item.type === type);
    const sortedArray = filteredArray.sort((a, b) => a.name - b.name);
    return sortedArray;
  };

  const filterCity = (arr, city) => {
    const filteredArray = arr.filter((item) => item.city === city);
    const sortedArray = filteredArray.sort((a, b) => a.name - b.name);
    return sortedArray;
  };

  const getFileSlug = (name) => {
    return name
      .normalize("NFD")
      .toLowerCase()
      .split(" ")
      .join("_")
      .replace(".", "")
      .replace(/[\u0300-\u036f]/g, "");
  };

  const bancadaFederal = sortAndFilter(parlamentares, "federal");
  const bancadaEstadual = sortAndFilter(parlamentares, "estadual");
  const bancadaMunicipal = sortAndFilter(parlamentares, "vereador");

  const cidades = [
    ...new Set(bancadaMunicipal.map((item) => item.city)),
  ].sort();

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };

  switch (type) {
    case "federal":
      return (
        <Fragment>
          <Helmet>
            <title>PSOL/RJ | Deputados Federais</title>
          </Helmet>
          <Section wrapperStyle={{ backgroundColor: "var(--theme-secondary)" }}>
            <h1 style={{ color: "var(--offwhite)" }}>
              Deputados Federais PSOL/RJ (2019-2023)
            </h1>
            <Parlamentares parlamentaresObj={bancadaFederal} type="federal" />
          </Section>
          <Section>
            {bancadaFederal.map((item) => (
              <div className="parlamentar-bio-section-wrapper" key={item.id}>
                <Parlamentar
                  name={item.name}
                  type={item.type}
                  gender={item.gender}
                  city={item.city}
                  socials={item.socials}
                  image={item.image}
                />
                <BioParlamentar
                  name={item.name}
                  bioSlug={getFileSlug(item.name)}
                />
              </div>
            ))}
          </Section>
        </Fragment>
      );
    case "estadual":
      return (
        <Fragment>
          <Helmet>
            <title>PSOL/RJ | Deputados Estaduais</title>
          </Helmet>
          <Section wrapperStyle={{ backgroundColor: "var(--theme-primary)" }}>
            <h1 style={{ color: "var(--theme-secondary)" }}>
              Deputados Estaduais PSOL/RJ (2019-2023)
            </h1>

            <Parlamentares parlamentaresObj={bancadaEstadual} />
          </Section>
          <Section>
            {bancadaEstadual.map((item) => (
              <div className="parlamentar-bio-section-wrapper" key={item.id}>
                <Parlamentar
                  name={item.name}
                  type={item.type}
                  gender={item.gender}
                  city={item.city}
                  socials={item.socials}
                  image={item.image}
                />
                <BioParlamentar
                  name={item.name}
                  bioSlug={getFileSlug(item.name)}
                />
              </div>
            ))}
          </Section>
        </Fragment>
      );
    case "municipal":
      return (
        <Fragment>
          <Helmet>
            <title>PSOL/RJ | Vereadores</title>
          </Helmet>
          <Section wrapperStyle={{ backgroundColor: "var(--offwhite)" }}>
            <h1 style={{ color: "var(--theme-secondary)" }}>
              Vereadores do PSOL no Estado do RJ (2021-2025)
            </h1>
            <div className="vereadores-city-link">
              {cidades.map((city) => (
                <HashLink
                  to={`#${city}`}
                  scroll={(el) => scrollWithOffset(el, 150)}
                >
                  <div className="city-link">{city}</div>
                </HashLink>
              ))}
            </div>
          </Section>

          {cidades.map((cidade) => (
            <Fragment>
              <Section
                id={cidade}
                wrapperStyle={{ backgroundColor: "var(--theme-secondary)" }}
              >
                <Fragment key={cidade}>
                  <h1 style={{ color: "var(--offwhite)" }}>{cidade}</h1>
                  <Parlamentares
                    parlamentaresObj={filterCity(bancadaMunicipal, cidade)}
                    type="vereador"
                  />
                </Fragment>
              </Section>
              <Section>
                {filterCity(bancadaMunicipal, cidade).map((item) => (
                  <div
                    className="parlamentar-bio-section-wrapper"
                    key={item.id}
                  >
                    <Parlamentar
                      name={item.name}
                      type={item.type}
                      gender={item.gender}
                      city={item.city}
                      socials={item.socials}
                      image={item.image}
                    />
                    <BioParlamentar
                      name={item.name}
                      bioSlug={getFileSlug(item.name)}
                    />
                  </div>
                ))}
              </Section>
            </Fragment>
          ))}
        </Fragment>
      );
    default:
      return null;
  }
};

export default LayoutBancada;
