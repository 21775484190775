import React, { Fragment, useRef } from "react";
import Section from "../content/Section";

const Programa = () => {
  const videoRef = useRef();

  return (
    <Fragment>
      <Section
        containerStyle={{ padding: "0", width: "100%", maxWidth: "100%" }}
      >
        <div
          className="video-cover"
          style={{
            position: "absolute",
            width: "100%",
            minHeight: "800px",
            marginTop: "-300px",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            zIndex: 5,
          }}
        ></div>
        <img
          src="/media/img/programa/direito_ao_futuro.png"
          title={"Direito ao Futuro"}
          alt="Direito ao Futuro"
          style={{
            position: "absolute",
            zIndex: 10,
            width: "clamp(200px, calc(100% - 80px), 500px)",
            left: "clamp(40px, 1000px, calc((100% - 1600px) / 2))",
            marginRight: "40px",
          }}
        ></img>
        <iframe
          ref={useRef()}
          title="Direito ao Futuro"
          allow="autoplay; fullscreen"
          src="https://player.vimeo.com/video/675586577?loop=1&amp;autoplay=1&amp;background=0&amp;title=0&amp;byline=0&amp;portrait=0&amp;player_id=iframe46847"
          webkitallowfullscreen=""
          mozallowfullscreen=""
          allowfullscreen=""
          width="1920px"
          class="intrinsic-ignore"
          style={{
            opacity: "1",
            visibility: "visible",
            height: "800px",
            minHeight: "800px",
            maxHeight: "none",
            maxWidth: "none",
            position: "relative",
            display: "flex",
            marginTop: "-150px",
            marginBottom: "-150px",
            zIndex: "0",
            overflow: "hidden",
          }}
          id="player_1"
          data-ready="true"
          frameBorder="0"
        ></iframe>
      </Section>
      <Section
        wrapperStyle={{ backgroundColor: "var(--theme-secondary)", zIndex: 1 }}
      >
        <h1 style={{ color: "var(--offwhite)" }}>
          CONHEÇA O PROGRAMA DO PSOL PARA AS ELEIÇÕES 2022
        </h1>
        <h4
          style={{
            color: "var(--offwhite)",
            fontWeight: 500,
            fontSize: "1.1rem",
            maxWidth: "75ch",
            textAlign: "center",
            marginBottom: "0px",
          }}
        >
          Durante meses, realizamos um circuito de debates em todo o país e
          recebemos sugestões e propostas para o nosso programa de governo.
        </h4>
        <h4
          style={{
            color: "var(--offwhite)",
            fontWeight: 500,
            fontSize: "1.1rem",
            maxWidth: "75ch",
            textAlign: "center",
            marginBottom: "0px",
          }}
        >
          Agora, chegou a hora de mostrar o resultado. Faça o download, leia e
          ajude a divulgar o programa Direito ao Futuro!
        </h4>
        <a
          href="https://programa.psol50.org.br/"
          target="_blank"
          rel="noopener noreferrer"
          className="pgm-link"
        >
          <div className="pgm-btn">Acesse a Página</div>
        </a>
      </Section>
      <Section
        wrapperStyle={{
          backgroundColor: "var(--offwhite)",
          zIndex: 1,
        }}
        containerStyle={{
          maxWidth: "100ch",
        }}
      >
        <h1>20 PROPOSTAS DO PSOL PARA MUDAR O RIO</h1>
        <h3 className="mb-3">
          Garantir direitos, reduzir a desigualdade social, democratizar o poder
          e investir no futuro do estado{" "}
        </h3>
        <p>
          <strong>1. Criar o programa Renda Básica Fluminense</strong> para
          garantir um auxílio financeiro permanente às 600 mil famílias mais
          pobres do estado (as 10% mais pobres), tendo como objetivo consolidar
          uma política estrutural de transferência de renda voltada para a
          erradicação da extrema pobreza e a superação das desigualdades
          sociais.
        </p>
        <p>
          <strong>2. Rescindir o Regime de Recuperação Fiscal (RRF)</strong>,
          auditar a dívida do estado do Rio de Janeiro e repactuar com o governo
          federal os termos do pagamento da dívida do estado, sem qualquer
          cláusula que retire direitos da população (privatizações, demissões,
          proibição de concurso, teto de gastos, extinção de órgãos etc),
          levando em conta na renegociação a arrecadação perdida com a Lei
          Kandir e a possibilidade de conversão de parte da dívida em obrigações
          de investimentos estruturais por parte do estado do Rio de Janeiro,
          que alavanquem e diversifiquem a economia fluminense, propiciem o
          desenvolvimento social e promovam a sustentabilidade ambiental.
        </p>
        <p>
          <strong>
            3. Implementar, nos termos da ADPF 635, um programa de redução da
            letalidade policial e controle de violações de direitos humanos
            cometidas pelas instituições de segurança
          </strong>
          , regulamentar o uso da força por agentes do Estado, proibindo o uso
          de técnicas, equipamentos, armas e munições que provoquem risco
          injustificado (tanto no que se refere ao armamento letal quanto no
          caso de armamento menos letal), e retirar das ruas os servidores de
          órgãos de segurança envolvidos em casos de grave violência, dando
          tarefas administrativas para os agentes envolvidos em casos de menor
          gravidade e expulsando das corporações aqueles envolvidos em casos de
          maior gravidade (seja de violência ou de corrupção).
        </p>
        <p>
          <strong>4. Recriar a Secretaria Estadual de Segurança Pública</strong>
          , integrar os diferentes órgãos de segurança do Estado e promover uma
          reforma das polícias que permita valorizar o servidor público,
          garantir democracia interna e fortalecer o controle social, tendo como
          objetivo a defesa da vida, a prevenção de conflitos e o fim da
          ideologia da guerra.{" "}
        </p>
        <p>
          <strong>
            5. Liderar um pacto estadual de redução da violência rural, urbana e
            doméstica
          </strong>{" "}
          (em especial, estupros, homicídios e feminicídios) e construir uma
          rede municipal de apoio, acolhimento, proteção e denúncia para as
          vítimas e seus familiares, com foco especial nos casos relacionados à
          discriminação motivada por preconceito contra mulheres, negros,
          população LGBTQIA+ ou pessoas com deficiência.{" "}
        </p>
        <p>
          <strong>6. Promover a reforma agrária</strong>, fazer a transição para
          uma economia agroecológica e implementar um programa de combate à fome
          e promoção da segurança alimentar e nutricional em todas as regiões do
          estado.{" "}
        </p>
        <p>
          <strong>7. Defender a educação crítica e democrática</strong>,
          suspender a Reforma do Ensino Médio no estado do Rio de Janeiro,
          envolver a comunidade escolar na construção da política pedagógica,
          radicalizar a democracia nas escolas, valorizar os profissionais de
          educação, garantir uma política educacional inclusiva e efetivar um
          modelo de educação integral na rede estadual de ensino, com o objetivo
          de integrar as políticas de esporte, arte e cultura aos programas de
          educação e transformar as escolas em polos de produção de pensamento
          crítico, preservação da memória local e promoção da cultura popular,
          ressignificando a sua relação com os territórios do estado.{" "}
        </p>
        <p>
          <strong>8. Fortalecer o Sistema Único de Saúde</strong>, aumentar os
          recursos destinados ao cuidado em saúde e à vigilância epidemiológica,
          sanitária e ambiental e fazer a transição do sistema atual, que
          prioriza a gestão privada, para um sistema de gestão pública e direta,
          garantindo o progressivo encerramento de todas as formas de
          privatização e terceirização da Saúde: OS’s e OSCIP’s, Fundações
          Estatais de Direito Privado, Empresas Públicas de Direito Privado e
          Parcerias Público-Privadas.{" "}
        </p>
        <p>
          <strong>
            9. Investir no complexo industrial de saúde fluminense
          </strong>{" "}
          e transformar o estado do Rio de Janeiro no maior polo de inovação em
          saúde da América Latina, com foco em pesquisa e produção de vacinas,
          medicamentos, insumos estratégicos e equipamentos técnicos de
          biotecnologia, em especial, no que se refere ao cuidado em saúde e à
          vigilância epidemiológica, sanitária e ambiental.{" "}
        </p>
        <p>
          <strong>
            10. Enfrentar a máfia dos transportes para recuperar o controle
            público sobre a gestão do sistema
          </strong>
          , criar uma autoridade metropolitana de mobilidade, garantir a gestão
          pública da bilhetagem, implementar a integração tarifária dos
          diferentes modos de transporte estaduais e municipais (trem, barcas,
          metrô, ônibus e vans) e reduzir progressivamente o valor da passagem
          até que seja possível oferecer um serviço público, gratuito e
          universal.{" "}
        </p>
        <p>
          <strong>
            {" "}
            11. Priorizar uma política de enfrentamento à emergência climática
          </strong>{" "}
          e adotar medidas para transformar a matriz energética, reduzir o
          consumo de fontes de energia fóssil, diminuir a emissão de gases de
          efeito estufa, combater a poluição, zerar o desmatamento e prevenir
          desastres socioambientais no estado do Rio de Janeiro.{" "}
        </p>
        <p>
          <strong>12. Revogar a reforma da previdência de 2017</strong> (Lei
          7606/2017) e retornar à alíquota de 11% de contribuição
          previdenciária.{" "}
        </p>
        <p>
          <strong>
            13. Fim de todas as isenções fiscais que não ofereçam ao Estado
            retorno compatível
          </strong>{" "}
          em termos de geração de trabalho digno, distribuição de renda e
          promoção da justiça socioambiental.{" "}
        </p>
        <p>
          <strong>
            14. Veto total à realização de Parcerias Público-Privadas
          </strong>{" "}
          para o desenvolvimento ou gestão de bem ou serviço público.{" "}
        </p>
        <p>
          <strong>
            15. Cancelar o processo de privatização e fortalecer a CEDAE como
            empresa pública de saneamento ambiental
          </strong>
          , garantindo um plano de carreira aos trabalhadores, valorizando o
          papel estratégico da empresa no desenvolvimento social do estado e
          aumentando a eficiência do serviço prestado à população.{" "}
        </p>
        <p>
          <strong>
            16. Criar o Banco Estadual de Desenvolvimento Social e Econômico do
            Rio de Janeiro{" "}
          </strong>
          (BEDS), com a função de articular os processos de captação e alocação
          de recursos em projetos de geração de trabalho digno, distribuição de
          renda e promoção da justiça socioambiental.{" "}
        </p>
        <p>
          <strong>
            17. Investir em obras de saneamento ambiental nas favelas,
            periferias e espaços populares
          </strong>
          , para garantir água limpa e esgoto tratado nas comunidades,
          oferecendo vagas de trabalho para os moradores dos locais onde forem
          realizadas as obras, começando pelos locais com maior demanda por
          serviços de saneamento ambiental.{" "}
        </p>
        <p>
          <strong>
            18. Ampliar os mecanismos de acesso à informação, consulta popular e
            controle social nos processos de tomada de decisão do Estado
          </strong>
          , fortalecer os conselhos de políticas públicas como instâncias
          deliberativas, adotar uma política de transparência ativa e dados
          abertos, efetivar a governança digital entre as redes governamentais,
          investir no uso de tecnologia de informação na gestão pública,
          realizar conferências populares regulares para deliberar a política de
          desenvolvimento regional e instituir um programa estadual de orçamento
          participativo.{" "}
        </p>
        <p>
          <strong>
            19. Liderar um pacto entre governo do Estado, empresas e sociedade
            civil para combater a discriminação salarial
          </strong>{" "}
          motivada por preconceitos de gênero, raça, orientação sexual ou
          capacitismo nos setores públicos e privados do estado do Rio de
          Janeiro e exigir de fornecedores e terceirizados da administração
          pública estadual a adoção de boas práticas (como formação adequada e
          canais de denúncia).{" "}
        </p>
        <p>
          <strong>20. Proibir homenagens a torturadores e escravocratas</strong>{" "}
          nos monumentos, equipamentos e estabelecimentos do Estado.{" "}
        </p>
      </Section>
    </Fragment>
  );
};

export default Programa;
