import {
  FORM_SUBMIT,
  FORM_SUCCESS,
  FORM_FAIL,
  SEND_FORM_EMAIL,
  SEND_FORM_EMAIL_SUCCESS,
  SEND_FORM_EMAIL_FAILURE,
} from "./actionTypes";

// Config
import {
  subscriptionEndpoint,
  mailerEndpoint,
  mailerFiliaEndpoint,
} from "../config";

// axios
import axios from "axios";

// general submit function
export const formSubmit = (formData) => {
  return (dispatch) => {
    // set form to 'sending' status
    dispatch({ type: FORM_SUBMIT });

    // perform post request
    axios
      .post(subscriptionEndpoint, formData)
      .then((res) => {
        if (res.data.type === "success") {
          // Default Return
          return dispatch({
            type: FORM_SUCCESS,
            msg: "Obrigado por se inscrever. Tamo Junto!",
          });
        } else {
          console.log("error", res);
          return dispatch({
            type: FORM_FAIL,
            msg: "Algo deu errado. Por favor, tente novamente mais tarde.",
          });
        }
      })
      .catch((err) => {
        console.log("more complicated error", err);
        return dispatch({
          type: FORM_FAIL,
          msg: "Algo deu errado. Por favor, tente novamente mais tarde.",
        });
      });
  };
};

export const sendEmail = (subscriptionData, formName, mailTo) => {
  return (dispatch) => {
    // set form to 'sending' status
    dispatch({ type: SEND_FORM_EMAIL });

    const mailData = {
      ...subscriptionData,
      formName,
      mailTo,
    };

    // perform post request
    axios
      .post(mailerEndpoint, mailData)
      .then((res) => {
        if (res.data.type === "success") {
          // Default Return
          return dispatch({
            type: SEND_FORM_EMAIL_SUCCESS,
            msg: "Obrigado por se inscrever. Tamo Junto!",
          });
        } else {
          console.log("error", res);
          return dispatch({
            type: SEND_FORM_EMAIL_FAILURE,
            msg: "Algo deu errado. Por favor, tente novamente mais tarde.",
          });
        }
      })
      .catch((err) => {
        console.log("more complicated error", err);
        return dispatch({
          type: SEND_FORM_EMAIL_FAILURE,
          msg: "Algo deu errado. Por favor, tente novamente mais tarde.",
        });
      });
  };
};

export const sendFiliacaoEmail = (subscriptionData, formName, mailTo) => {
  return (dispatch) => {
    // set form to 'sending' status
    dispatch({ type: SEND_FORM_EMAIL });

    const mailData = {
      ...subscriptionData,
      formName,
      mailTo,
    };

    // perform post request
    axios
      .post(mailerFiliaEndpoint, mailData)
      .then((res) => {
        if (res.data.type === "success") {
          // Default Return
          return dispatch({
            type: SEND_FORM_EMAIL_SUCCESS,
            msg: "Obrigado por se inscrever. Tamo Junto!",
          });
        } else {
          console.log("error", res);
          return dispatch({
            type: SEND_FORM_EMAIL_FAILURE,
            msg: "Algo deu errado. Por favor, tente novamente mais tarde.",
          });
        }
      })
      .catch((err) => {
        console.log("more complicated error", err);
        return dispatch({
          type: SEND_FORM_EMAIL_FAILURE,
          msg: "Algo deu errado. Por favor, tente novamente mais tarde.",
        });
      });
  };
};
