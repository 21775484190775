import React from "react";

// Components
import Parlamentar from "./Parlamentar";

// CSS
import "../../../static/css/layout/content/parlamentares.css";

const Parlamentares = ({ parlamentaresObj = [], type }) => {
  return (
    <div
      className={
        type === "federal"
          ? "parlamentares__container parlamentares__container-federal"
          : type === "vereador"
          ? "parlamentares__container parlamentares__container-municipal"
          : "parlamentares__container"
      }
    >
      {parlamentaresObj.length > 0
        ? parlamentaresObj.map((parlamentar) => (
            <Parlamentar
              key={parlamentar.id}
              name={parlamentar.name}
              type={parlamentar.type}
              gender={parlamentar.gender}
              city={parlamentar.city}
              socials={parlamentar.socials}
              image={parlamentar.image}
            />
          ))
        : null}
    </div>
  );
};

export default Parlamentares;
