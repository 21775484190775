import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

// FontAwesome

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faRotateLeft } from "@fortawesome/free-solid-svg-icons";

// CSS
import "../../../static/css/layout/pages/notfound.css";

const NotFound = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="notfound__wrapper">
      <div className="notfound__container">
        <div className="notfound-oops">Ooops!</div>
        <div className="notfound-subtitle">
          Não conseguimos encontrar a página que você está procurando!
        </div>

        <div className="notfound-text">
          A página{" "}
          <span style={{ color: "var(--theme-secondary)", fontWeight: "800" }}>
            {location.pathname}
          </span>{" "}
          não existe.
          <br />
          Talvez você tenha digitado algo errado. Talvez alguma coisa no site
          não esteja funcionando. Enquanto a gente resolve o problema, você pode
          voltar para a página principal ou para a última página que você
          visitou
        </div>
        <div className="notfound-btn-wrapper">
          <div className="notfound-btn" onClick={() => navigate(-1)}>
            <FontAwesomeIcon
              icon={faRotateLeft}
              style={{ marginRight: "8px" }}
            />
            Voltar
          </div>

          <Link to="/" alt="Voltar para o Início" title="Voltar para o Início">
            <div className="notfound-btn">
              <FontAwesomeIcon icon={faHome} style={{ marginRight: "8px" }} />
              Início
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
