import React from "react";

// CSS
import "../../../static/css/content/video.css";

const Video = ({ name, id }) => {
  return (
    <div className="video__container">
      <iframe
        src={`https://www.youtube.com/embed/${id}`}
        title={name}
        frameBorder="0"
        allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Video;
