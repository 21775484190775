import React, { Fragment } from "react";

// Config
import { siteInfo } from "../../../config";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { collapseNavbar } from "../../../actions/navbar";

// FontAwesome Icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

// Assets
import SocialIcon from "../../assets/SocialIcon";

// Custom Hooks
import useWindowDimensions from "../../hooks/useWindowDimensions";

// CSS
import "../../../static/css/layout/navbar/navbarcandidato.css";

const NavbarCandidato = ({ socialInfo, name, type, number, children }) => {
  const dispatch = useDispatch();

  const showCollapsedMenu = !useSelector(
    (state) => state.navbar.navbarCollapsed
  );

  const handleCollapseNavbar = () => {
    dispatch(collapseNavbar());
  };

  const { width } = useWindowDimensions();

  const whatsappURL = socialInfo.whatsappURL;
  const facebookURL = socialInfo.facebookURL;
  const instagramURL = socialInfo.instagramURL;
  const twitterURL = socialInfo.twitterURL;
  const tiktokURL = socialInfo.tiktokURL;

  return (
    <Fragment>
      <div className="navbar-cand__wrapper">
        <div className="navbar-cand__container">
          <div className="navbar-cand__logo">
            <div className="navbar-cand__name-wrap">
              <div className="navbar-cand__name">{name}</div>
              <div className="navbar-cand__type">{type}</div>
            </div>
            <div className="navbar-cand__divider" />
            <div className="navbar-cand__number">{number}</div>
          </div>
          <div className="navbar-cand__menu">{children}</div>
          <div className="navbar-cand__social">
            {whatsappURL ? (
              <SocialIcon type="whatsapp" url={whatsappURL} />
            ) : null}
            {facebookURL ? (
              <SocialIcon type="facebook" url={facebookURL} />
            ) : null}
            {instagramURL ? (
              <SocialIcon type="instagram" url={instagramURL} />
            ) : null}
            {twitterURL ? <SocialIcon type="twitter" url={twitterURL} /> : null}
            {tiktokURL ? <SocialIcon type="tiktok" url={tiktokURL} /> : null}
          </div>
          <div
            className="navbar__collapsed-icon"
            onClick={() => handleCollapseNavbar()}
          >
            <FontAwesomeIcon icon={faBars} color="var(--theme-secondary)" />
          </div>
        </div>
      </div>

      <div
        className={
          showCollapsedMenu
            ? "collapsed-menu__wrapper"
            : "collapsed-menu__wrapper menu-hide"
        }
      >
        <div className="collapsed-menu__container">
          <div className="collapsed-menu__logo">
            <img
              src={siteInfo.logoAlt}
              alt={siteInfo.name}
              title={siteInfo.name}
            />
          </div>
          <div className="collapsed-menu__menu">{children}</div>
          <div className="collapsed-menu__social">
            {whatsappURL ? (
              <SocialIcon type="whatsapp" url={whatsappURL} />
            ) : null}
            {facebookURL ? (
              <SocialIcon type="facebook" url={facebookURL} />
            ) : null}
            {instagramURL ? (
              <SocialIcon type="instagram" url={instagramURL} />
            ) : null}
            {twitterURL ? <SocialIcon type="twitter" url={twitterURL} /> : null}
            {tiktokURL ? <SocialIcon type="tiktok" url={tiktokURL} /> : null}
          </div>
          <div
            className="collapsed-menu__close"
            onClick={() => handleCollapseNavbar()}
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NavbarCandidato;
