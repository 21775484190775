import React, { Children } from "react";

// Config
import { siteInfo } from "../../../config";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

// Assets
import SocialIcon from "../../assets/SocialIcon";

// Images
import LogoPSOLLula from "../../../static/img/logo_psol_lula.svg";
import LogoFreixo from "../../../static/img/logo_freixo.svg";
import LogoMolon from "../../../static/img/logo_molon.svg";

const Footer = ({ socialInfo, logoFooter, children }) => {
  const whatsappURL = socialInfo.whatsappURL;
  const facebookURL = socialInfo.facebookURL;
  const instagramURL = socialInfo.instagramURL;
  const twitterURL = socialInfo.twitterURL;
  const tiktokURL = socialInfo.tiktokURL;

  return (
    <div className="footer__wrapper">
      <div className="footer__social">
        <div className="footer__social-container">
          {whatsappURL ? (
            <SocialIcon type="whatsapp" url={whatsappURL} />
          ) : null}
          {facebookURL ? (
            <SocialIcon type="facebook" url={facebookURL} />
          ) : null}
          {instagramURL ? (
            <SocialIcon type="instagram" url={instagramURL} />
          ) : null}
          {twitterURL ? <SocialIcon type="twitter" url={twitterURL} /> : null}
          {tiktokURL ? <SocialIcon type="tiktok" url={tiktokURL} /> : null}
        </div>
      </div>
      <div className="footer__divider"></div>
      <div className="footer__content">
        <div className="footer__content-container">
          <div className="footer__logo">
            <img
              src={siteInfo.logoAlt}
              alt={siteInfo.name}
              title={siteInfo.name}
            />
          </div>
          <div className="footer_menu-info-wrap">
            <div className="footer__menu">{children}</div>
            <div className="footer__info">
              <div className="footer__info-title">
                Partido Socialismo e Liberdade - PSOL
              </div>
              <div className="footer__info-subtitle">
                Diretório Estadual do Rio de Janeiro
              </div>
              <div
                className="footer__info-entry"
                style={{ marginBottom: "15px" }}
              >
                <strong>CNPJ:&nbsp;</strong>
                {siteInfo.cnpj}
              </div>
              <div className="footer__info-entry">
                <div className="footer__info-icon">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    style={{ marginRight: "8px", marginTop: "4px" }}
                  />
                </div>
                <div className="footer__info-entry-content">
                  {siteInfo.address}
                </div>
              </div>
              <div className="footer__info-entry">
                <div className="footer__info-icon">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ marginRight: "8px", marginTop: "4px" }}
                  />
                </div>
                <div className="footer__info-entry-content">
                  {siteInfo.email}
                </div>
              </div>
              <div className="footer__info-entry">
                <div className="footer__info-icon">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ marginRight: "8px", marginTop: "4px" }}
                  />
                </div>
                <div className="footer__info-entry-content">
                  {siteInfo.phone}
                </div>
              </div>
            </div>
          </div>
          <div className="footer__col">
            <a
              href="https://psolrj.com.br"
              rel="noopener noreferrer"
              alt="PSOL com Lula - 2022"
              title="PSOL com Lula - 2022"
            >
              <img
                src={LogoPSOLLula}
                alt="PSOL com Lula - 2022"
                title="PSOL com Lula - 2022"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
