import React from "react";

// FontAwesome Icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreativeCommons,
  faCreativeCommonsBy,
} from "@fortawesome/free-brands-svg-icons";

// CSS
import "../../../static/css/layout/footer/footer.css";

const SubFooter = ({ legalContent }) => {
  return (
    <div className="subfooter__wrapper">
      <div className="subfooter__container">
        <div className="subfooter__legal">{legalContent}</div>
        <div className="subfooter__license-credits">
          <div className="subfooter__license">
            <FontAwesomeIcon
              icon={faCreativeCommons}
              title="A reprodução é livre, desde que citada a fonte"
            />
            <FontAwesomeIcon
              icon={faCreativeCommonsBy}
              title="A reprodução é livre, desde que citada a fonte"
            />
          </div>
          <div className="subfooter__credits">
            <span className="credits">
              Desenvolvido por{" "}
              <a
                href="https://contrafluxo.io"
                target="_blank"
                rel="noopener noreferrer"
                alt="Contrafluxo"
                title="Contrafluxo"
              >
                Contrafluxo
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubFooter;
