import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useParams, Navigate } from "react-router-dom";

// Candidatos
import { candidatos } from "../../config";

// Components
import NavbarCandidato from "./navbar/NavbarCandidato";
import NavbarLink from "./navbar/NavbarLink";
import Section from "./content/Section";
import BioCandidato from "./content/BioCandidato";
import Video from "./content/Video";
import Financie from "./content/Financie";
import FooterCandidato from "./footer/FooterCandidato";

import { parseParlamentarType } from "../utils/parlamentarParser";

// Hooks
import ScrollToTop from "../hooks/scrollToTop";

// Assets
import Form from "../content/Form";
import FormWidget from "../FormWidget";

const LayoutCandidato = () => {
  ScrollToTop();
  const { candidato } = useParams();

  const getSlug = (name) => {
    return name
      .normalize("NFD")
      .toLowerCase()
      .split(" ")
      .join("-")
      .replace(/[\u0300-\u036f]/g, "");
  };

  const getFileSlug = (name) => {
    return name
      .normalize("NFD")
      .toLowerCase()
      .split(" ")
      .join("_")
      .replace(/[\u0300-\u036f]/g, "");
  };

  const candidatoData = candidatos.filter(
    (item) => getSlug(item.name) === candidato
  )[0];

  // If Not Found, Go Back to Home
  if (candidatoData === undefined) {
    return <Navigate to="/" replace />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {`PSOL/RJ | ${candidatoData.name} - ${candidatoData.number}`}
        </title>
      </Helmet>
      <NavbarCandidato
        socialInfo={candidatoData.socials}
        name={candidatoData.name}
        number={candidatoData.number}
        type={parseParlamentarType(candidatoData.type, candidatoData.gender)}
        gender={candidatoData.gender}
      >
        <NavbarLink to="#biografia" title="Biografia" />
        {candidatoData.featuredFinancing ? (
          <NavbarLink to="#financie" title="Financie" />
        ) : null}
        <NavbarLink to="#contato" title="Contato" />
      </NavbarCandidato>
      <Section
        type="hero"
        wrapperStyle={{ alignItems: "flex-start", minHeight: "unset" }}
        containerStyle={{ padding: 0, width: "100%", maxWidth: "unset" }}
      >
        <img
          src={`/media/banners/banner_${candidatoData.type}_${getFileSlug(
            candidatoData.name
          )}.png`}
          alt={candidatoData.name}
          title={candidatoData.name}
          width="100%"
        />
      </Section>
      <Section id="biografia">
        <BioCandidato
          name={candidatoData.name}
          bioSlug={getFileSlug(candidatoData.name)}
        />
      </Section>
      {candidatoData.featuredVideoId ? (
        <Section containerStyle={{ padding: 0, width: "100%" }}>
          <Video name={candidatoData.name} id={candidatoData.featuredVideoId} />
        </Section>
      ) : null}
      {candidatoData.featuredFinancing ? (
        <Section
          id="financie"
          title={`Ajude a Financiar a Campanha de ${candidatoData.name}`}
          wrapperStyle={{ backgroundColor: "var(--theme-primary)" }}
        >
          <Financie financieURL={candidatoData.featuredFinancing} />
        </Section>
      ) : null}
      <Section
        id="contato"
        title={`Entre em Contato com ${candidatoData.name}`}
      >
        <Form>
          <FormWidget
            messageField
            formName={`Contato - ${candidatoData.name}`}
            messageTo={candidatoData.email}
            successMsgLine1="Obrigado por se inscrever"
            successMsgLine2=""
            useRecaptcha
            defaultForm="IK60FF4EdZBi00ApwxM9"
            defaultTracker="y5ejL8ftH7gGaqGARaLu"
          />
        </Form>
      </Section>
      <FooterCandidato
        name={candidatoData.name}
        number={candidatoData.number}
        cnpj={candidatoData.cnpj}
        faixa={candidatoData.faixa}
        domain={candidatoData.domain}
      ></FooterCandidato>
    </Fragment>
  );
};

export default LayoutCandidato;
