import React from "react";

// Config
import { siteInfo } from "../../../config";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

// Images
import LogoPSOLLula from "../../../static/img/logo_psol_lula.svg";
import LogoLula from "../../../static/img/logo_lula.svg";
import LogoFreixo from "../../../static/img/logo_freixo.svg";
import LogoMolon from "../../../static/img/logo_molon.svg";

const Footer = ({ name, number, faixa, cnpj, domain, children }) => {
  return (
    <div className="footer__wrapper">
      <div className="footer__content">
        <div className="footer-cand__content-container">
          <div className="footer__col">
            <a
              href="https://psolrj.com.br"
              rel="noopener noreferrer"
              alt="PSOL com Lula - 2022"
              title="PSOL com Lula - 2022"
            >
              <img
                src={LogoPSOLLula}
                alt="PSOL com Lula - 2022"
                title="PSOL com Lula - 2022"
              />
            </a>
          </div>
          <div className="footer__col">
            <a
              href="https://lula.com.br/"
              target="_blank"
              rel="noopener noreferrer"
              alt="Lula 13 - Presidente"
              title="Lula 13 - Presidente"
            >
              <img
                src={LogoLula}
                alt="Lula 13 - Presidente"
                title="Lula 13 - Presidente"
              />
            </a>
          </div>
          <div className="footer__col">
            <a
              href="http://www.marcelofreixo.com.br/"
              target="_blank"
              rel="noopener noreferrer"
              alt="Freixo 40 - Governador"
              title="Freixo 40 - Governador"
            >
              <img
                src={LogoFreixo}
                alt="Freixo 40 - Governador"
                title="Freixo 40 - Governador"
              />
            </a>
          </div>
          <div className="footer_menu-info-wrap">
            <div className="footer__info">
              <div className="footer__info-title">
                {name} - {number}
              </div>
              <div
                className="footer__info-entry"
                style={{ marginBottom: "15px" }}
              >
                <strong>CNPJ:&nbsp;</strong>
                {cnpj}
              </div>
              {faixa === "basico" ? (
                <div className="footer__info-entry">
                  <div className="footer__info-icon">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      style={{ marginRight: "8px", marginTop: "4px" }}
                    />
                  </div>
                  <div className="footer__info-entry-content">
                    {siteInfo.address}
                  </div>
                </div>
              ) : null}
              {faixa === "basico" ? (
                <div className="footer__info-entry">
                  <div className="footer__info-icon">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={{ marginRight: "8px", marginTop: "4px" }}
                    />
                  </div>
                  <div className="footer__info-entry-content">
                    contato@{domain}
                  </div>
                </div>
              ) : null}
              {faixa === "basico" ? (
                <div className="footer__info-entry">
                  <div className="footer__info-icon">
                    <FontAwesomeIcon
                      icon={faPhone}
                      style={{ marginRight: "8px", marginTop: "4px" }}
                    />
                  </div>
                  <div className="footer__info-entry-content">
                    {siteInfo.phoneAlt}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
