import React from "react";

// CSS
import "../../../static/css/layout/content/section.css";

const Section = ({
  id,
  type,
  title,
  wrapperStyle,
  containerStyle,
  titleColor,
  heroImage,
  customHeroClass,
  children,
}) => {
  switch (type) {
    case "hero":
      return (
        <div
          id={id}
          className={`section__wrapper-hero ${customHeroClass}`}
          style={{
            ...wrapperStyle,
            backgroundImage: `url(${heroImage})`,
            backgroundPosition: "50% 50%",
            backgroundSize: "cover",
          }}
        >
          <div className="section__container-hero" style={containerStyle}>
            {children}
          </div>
        </div>
      );
    default:
      return (
        <div id={id} className="section__wrapper" style={wrapperStyle}>
          <div className="section__container" style={containerStyle}>
            {title ? <h1 style={{ color: titleColor }}>{title}</h1> : null}
            {children}
          </div>
        </div>
      );
  }
};

export default Section;
