// JSON
import statesBR from "../../static/json/states.json";
import citiesBR from "../../static/json/cities.json";

export const getStateCode = (state) => {
  const stateObj = statesBR.filter((item) => item.sigla === state);
  return stateObj[0].id;
};

export const getCityCode = (state, city) => {
  const filteredCities = citiesBR.filter(
    (city) => city.microrregiao.mesorregiao.UF.sigla === state
  );

  const cityObj = filteredCities.filter((cityItem) => cityItem.nome === city);
  return cityObj[0].id;
};
