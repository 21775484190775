import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";

// Config
import { siteInfo, socialInfo } from "../../config";

// Layout
import Navbar from "./navbar/Navbar";
import NavbarLink from "./navbar/NavbarLink";

import Body from "./body/Body";

import Footer from "./footer/Footer";
import FooterLink from "./footer/FooterLink";

import SubFooter from "./footer/SubFooter";

// CSS
import "../../static/css/public/main.css";
import "../../static/css/public/vars.css";

const Layout = () => {
  return (
    <Fragment>
      <Navbar socialInfo={socialInfo}>
        <NavbarLink type="navigation-menu" to="#" title="Partido">
          <NavbarLink
            type="navigation-submenu"
            to="/partido/quem-somos"
            title="Quem Somos"
          />
          <NavbarLink
            type="navigation-submenu"
            to="/partido/organizacao"
            title="Organização"
          />
          <NavbarLink
            type="navigation-submenu"
            to="/partido/programa-de-governo"
            title="Programa de Governo"
          />
          <NavbarLink
            type="navigation-submenu"
            to="/partido/filiacao"
            title="Filie-se"
          />
        </NavbarLink>
        {/* <NavbarLink to="/noticias" title="Notícias"></NavbarLink> */}
        <NavbarLink type="navigation-menu" to="#" title="Bancada">
          <NavbarLink
            type="navigation-submenu"
            to="/bancada/federal"
            title="Deputados Federais"
          />
          <NavbarLink
            type="navigation-submenu"
            to="/bancada/estadual"
            title="Deputados Estaduais"
          />
          <NavbarLink
            type="navigation-submenu"
            to="/bancada/municipal"
            title="Vereadores"
          />
        </NavbarLink>
        <NavbarLink to="/eleicoes" title="Eleições 2022" />
      </Navbar>
      <Body>
        <Outlet />
      </Body>
      <Footer socialInfo={socialInfo}>
        <FooterLink to="#" title="Partido"></FooterLink>
        {/*<FooterLink to="/noticias" title="Notícias" />*/}
        <FooterLink to="#" title="Bancada"></FooterLink>
        <FooterLink to="/eleicoes" title="Eleições 2022" />
      </Footer>
      <SubFooter legalContent={`${siteInfo.name} | CNPJ: ${siteInfo.cnpj}`} />
    </Fragment>
  );
};

export default Layout;
