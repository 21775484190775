import React, { useState, useEffect } from "react";

// Marked
import Markdown from "markdown-to-jsx";

// CSS
import "../../../static/css/content/biography.css";

const BioCandidato = ({ name, bioSlug }) => {
  const [bio, setBio] = useState("");

  useEffect(() => {
    import(`../../../static/biography/${bioSlug}.md`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setBio(res))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, [bioSlug]);

  return (
    <div className="bio__wrapper">
      <div className="bio__container">
        <h1>Quem é {name}?</h1>
        <div className="bio__content">
          <Markdown>{bio}</Markdown>
        </div>
      </div>
    </div>
  );
};

export default BioCandidato;
