import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

// Config
import { siteInfo } from "../../../config";

// Components
import Section from "../content/Section";
import Form from "../../content/Form";
import FiliacaoWidget from "../../FiliacaoWidget";

// Hooks
import ScrollToTop from "../../hooks/scrollToTop";

const Filiacao = () => {
  ScrollToTop();
  return (
    <Fragment>
      <Helmet>
        <title>PSOL/RJ | Filie-se</title>
      </Helmet>
      <Section title="Filie-se - Venha Organizar a Sua Indignação!">
        <div className="text__container">
          <p>
            Passamos por um dos períodos mais delicados de nossa história, mas
            temos esperança. Todos os dias nos vemos indignados com as ações de
            uns e a covardia de outros, mas há algo nascendo no horizonte. Por
            isso precisamos nos juntar desde já para ampliar as suas e as nossas
            forças e fortalecer as lutas necessárias para vencermos o
            autoritarismo, a miséria e os enormes desafios impostos pela
            pandemia a uma cidade historicamente desigual e baseada na
            desassistência dos que mais precisam.
          </p>
          <p>
            Você já deve ter visto nossa militância e parlamentares nas
            principais lutas da cidade. Seja por uma educação de qualidade ou
            por melhorias na saúde, o <strong>PSOL</strong> sempre tá lá. Seja
            contra a remoção de moradores ou contra a repressão policial, o{" "}
            <strong>PSOL</strong> tá lá. Seja pela transparência nos transportes
            ou pelos direitos dos trabalhadores, o <strong>PSOL</strong> também
            tá. É isso: nos encontramos na luta popular. Agora é tempo da gente
            ajudar você a organizar sua indignação. Venha para o{" "}
            <strong>PSOL, ele tá ON!</strong> Filie-se ainda este mês.
          </p>
          <p>
            Preencha o formulário ao final para iniciar a etapa, mas antes leia
            com atenção o trecho abaixo:
          </p>
          <h3>Como se filiar ao PSOL/RJ</h3>
          <p>
            Para nós do <strong>PSOL</strong>, a filiação é mais que preencher
            uma ficha. É para além. É estar <strong>ON</strong>, presente no dia
            a dia do partido, das lutas da cidade, do seu bairro, do seu setor
            de atuação, participando ativamente dos núcleos e setoriais, das
            campanhas, congressos e atos. É importante ser uma filiação
            militante com corpo e alma, atuante, opinativa e que queira conhecer
            o PSOL para estar ON e ajudar a organizar a nossa indignação.
          </p>
          <h3>Fique ON no PSOL. Confira o passo a passo para a filiação</h3>
          <p>
            1) <strong>Formulário de Intenção de Filiação</strong>: o primeiro
            passo para se filiar ao PSOL é preencher o Formulário de Intenção de
            Filiação que está logo abaixo. Com isso, iremos enviar algumas
            mensagens com conteúdos importantes sobre o partido e a militância.
          </p>
          <p>
            2) <strong>Conhecer o PSOL</strong>: para se filiar é fundamental
            que você conheça nosso histórico, bandeiras de luta e saiba como é
            possível participar ativamente da vida do partido. Para isso, visite
            e navegue pelo site, leia nosso estatuto, siga as redes sociais do
            PSOL e de nossos parlamentares, conheça e participe de nossos
            núcleos e setoriais… Chega junto!
          </p>
          <p>
            3) <strong>Ficha de Filiação e Documentos</strong>: após recebermos
            seus dados, o PSOL vai entrar em contato por e-mail e/ou whatsapp
            para trocar uma ideia e assim, enviar a Ficha de Filiação e os
            documentos necessários para concluir o processo.
          </p>
          <p>
            A próxima janela de filiação abre entre outubro e novembro.
            Entraremos em contato perto da data para continuarmos os processos
            de filiação ao PSOL!
          </p>
          <p>
            <strong>Vamos juntos/as?</strong>
          </p>
        </div>
      </Section>
      <Section
        title="Formulário de Intenção de Filiação ao Partido Socialismo e Liberdade - PSOL/RJ"
        wrapperStyle={{ backgroundColor: "var(--theme-primary)" }}
      >
        <Form>
          <FiliacaoWidget
            formName="Filiação"
            messageTo={siteInfo.forwardEmail}
            successMsgLine1="Obrigado por se inscrever"
            successMsgLine2=""
            useRecaptcha
            defaultForm="T83tfMzx5souKnnOqh3j"
            defaultTracker="l9fhtyatoM5HSk9LAoyU"
          />
        </Form>
      </Section>
      <Section title="Perguntas Frequentes">
        <div className="text__container">
          <p>
            <strong>
              Preenchendo esse formulário do site eu já estou filiado ao PSOL?
            </strong>
            <br />
            Não. Esse formulário acima é para manifestar sua intenção de se
            filiar. Após enviar seus dados, vamos entrar em contato para te
            informar sobre documentação e preenchimento da Ficha de Filiação.
          </p>
          <p>
            <strong>Quando acontecerá minha filiação?</strong>
            <br />
            Depois de enviada a Ficha de Filiação assinada e seus documentos
            pessoais, o PSOL analisa e, caso esteja tudo certo, informa ao TSE
            sobre sua filiação. Esse processo pode levar alguns dias e você pode
            acompanhar a conclusão dele e emitir sua certidão de filiação
            partidária no FILIA:{" "}
            <a
              href="https://filia-externo.tse.jus.br/#/"
              target="_blank"
              rel="noopener noreferrer"
              alt="FiliaWeb"
              title="Filia"
            >
              https://filia-externo.tse.jus.br/#/
            </a>
          </p>
        </div>
      </Section>
    </Fragment>
  );
};

export default Filiacao;
