import React from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { collapseNavbar } from "../../../actions/navbar";

// Router
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

// CSS
import "../../../static/css/layout/navbar/navbarlink.css";

const NavbarLink = ({ type, to, title, children, featured = false }) => {
  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };
  const dispatch = useDispatch();

  const showCollapsedMenu = !useSelector(
    (state) => state.navbar.navbarCollapsed
  );

  const handleCollapseNavbar = () => {
    if (showCollapsedMenu) {
      dispatch(collapseNavbar());
    }
  };
  switch (type) {
    case "external":
      return (
        <a
          className={featured ? "navlink__a navlink__featured" : "navlink__a"}
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          title={title}
          alt={title}
          onClick={handleCollapseNavbar}
        >
          <div className="navlink__wrapper">{title}</div>
          <div className="navlink__underscore"></div>
        </a>
      );

    case "navigation":
      return (
        <Link
          className={featured ? "navlink__a navlink__featured" : "navlink__a"}
          to={to}
          title={title}
          alt={title}
          onClick={handleCollapseNavbar}
        >
          <div className="navlink__wrapper">{title}</div>
          <div className="navlink__underscore"></div>
        </Link>
      );
    case "navigation-menu":
      return (
        <Link
          className={
            featured
              ? "navlink__a navlink__menu navlink__featured"
              : "navlink__a navlink__menu"
          }
          to={to}
          title={title}
          alt={title}
        >
          <div className="navlink__wrapper">{title}</div>
          <div className="navlink__underscore"></div>
          <div className="navlink__menu-sub">
            <div className="navlink__menu-sub-spacer"></div>
            <div className="navlink__menu-sub-bg">{children}</div>
          </div>
        </Link>
      );
    case "navigation-submenu":
      return (
        <Link
          className={
            featured
              ? "navlink__a navlink__submenu navlink__featured"
              : "navlink__a navlink__submenu"
          }
          to={to}
          title={title}
          alt={title}
          onClick={handleCollapseNavbar}
        >
          <div className="navlink__wrapper">{title}</div>
          <div className="navlink__underscore"></div>
        </Link>
      );
    default:
      return (
        <HashLink
          className={featured ? "navlink__a navlink__featured" : "navlink__a"}
          smooth
          scroll={(el) => scrollWithOffset(el, 140)}
          title={title}
          alt={title}
          to={to}
          onClick={handleCollapseNavbar}
        >
          <div className="navlink__wrapper">{title}</div>
          <div className="navlink__underscore"></div>
        </HashLink>
      );
  }
};

export default NavbarLink;
