import React from "react";
import { Link } from "react-router-dom";

import { parseParlamentarType } from "../../utils/parlamentarParser";

const SnippetCandidato = ({ data }) => {
  const parseType = (type) => {
    if (type === "federal") {
      return "federais";
    } else {
      return "estaduais";
    }
  };

  const getFileSlug = (name) => {
    return name
      .normalize("NFD")
      .toLowerCase()
      .split(" ")
      .join("_")
      .replace(".", "")
      .replace(/[\u0300-\u036f]/g, "");
  };

  const getSlug = (name) => {
    return name
      .normalize("NFD")
      .toLowerCase()
      .split(" ")
      .join("-")
      .replace(/[\u0300-\u036f]/g, "");
  };
  return (
    <Link
      to={`/eleicoes/${parseType(data.type)}/${getSlug(data.name)}`}
      alt={data.name}
      title={data.name}
    >
      <div className="candidato-snippet-container">
        <div className="candidato-snippet-foto">
          <img
            src={`/media/img/candidatos/${getFileSlug(data.name)}.png`}
            alt={`${data.name} - ${data.number}`}
            title={`${data.name} - ${data.number}`}
          />
        </div>
        <div className="candidato-snippet-info">
          <div className="candidato-snippet-name">
            {data.name} | {data.number}
          </div>
          <div className="candidato-snippet-type">
            {parseParlamentarType(data.type, data.gender)}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SnippetCandidato;
