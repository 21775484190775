import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hooks-helper";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { formSubmit, sendEmail } from "../actions/forms";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationTriangle,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

// Components
import FormItem from "./assets/FormItem";

// Utils
import { getCityCode, getStateCode } from "./utils/formUtils";

// JSON
import statesBR from "../static/json/states.json";
import citiesBR from "../static/json/cities.json";

// CSS
import "../static/css/layout/form-widget.css";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

const FormWidget = ({
  messageField,
  messageTo,
  formName,
  successMsgLine1,
  successMsgLine2,
  useRecaptcha,
  defaultForm,
  defaultTracker,
}) => {
  const trackerId = new URLSearchParams(window.location.search).get("id");

  const dispatch = useDispatch();
  const formState = useSelector((state) => state.form);

  const [userIPAddress, setUserIPAddress] = useState(null);
  const [formToken, setFormToken] = useState();
  const [reToken, setReToken] = useState();

  const [citiesList, setCitiesList] = useState([]);
  const [formAlertMsg, setFormAlertMsg] = useState(null);

  // Get Tracker ID and Get IP Address
  useEffect(() => {
    if (trackerId) {
      setFormToken(trackerId);
    }

    fetch("https://api.ipify.org?format=jsonp?callback=?", {
      method: "GET",
      headers: {},
    })
      .then((res) => {
        return res.text();
      })
      .then((ipAddress) => {
        return setUserIPAddress(ipAddress);
      })
      .catch(() => {
        return setUserIPAddress(null);
      });
  }, []);

  // Form Values
  const subscriptionState = {
    name: "",
    email: "",
    phone: "",
    state: "",
    city: "",
    neighborhood: "",
    zipCode: "",
    message: "",
    optIn: true,
  };

  // Form Hook
  const [subscriptionData, setValue] = useForm(subscriptionState);

  const handleSubmit = () => {
    if (subscriptionData.name === "") {
      return setFormAlertMsg(`Por favor, preencha o campo nome`);
    }
    if (subscriptionData.email === "") {
      return setFormAlertMsg(`Por favor, preencha o campo email`);
    }
    if (subscriptionData.phone === "") {
      return setFormAlertMsg(`Por favor, preencha o campo whatsapp`);
    }
    if (subscriptionData.state === "") {
      return setFormAlertMsg(`Por favor, escolha um estado`);
    }
    if (subscriptionData.city === "") {
      return setFormAlertMsg(`Por favor, escolha uma cidade`);
    }
    if (subscriptionData.neighborhood === "") {
      return setFormAlertMsg(`Por favor, preencha o campo bairro`);
    }
    if (subscriptionData.message === "") {
      return setFormAlertMsg(`Por favor, preencha o campo mensagem`);
    }
    if (!subscriptionData.optIn) {
      return setFormAlertMsg(
        "O envio do formulário só é possível ao aceitar a política de privacidade."
      );
    }
    if (!reToken) {
      return setFormAlertMsg("Erro de Verificação: Bloqueado pelo reCaptcha");
    }

    setFormAlertMsg();

    const subData = {
      contact: {
        fullName: subscriptionData.name,
        email: subscriptionData.email,
        phoneNumber: subscriptionData.phone,
      },
      location: {
        country: "Brasil",
        state: subscriptionData.state,
        stateCode: getStateCode(subscriptionData.state),
        city: subscriptionData.city,
        cityCode: getCityCode(subscriptionData.state, subscriptionData.city),
        neighborhood: subscriptionData.neighborhood,
        zipcode: subscriptionData.zipCode,
      },
      connection: {
        ipAddress: userIPAddress,
      },
      other: {
        message: subscriptionData.message,
        optIn: subscriptionData.optIn,
        formName: formName,
      },
    };

    const submitData = {
      campaign: "psol_diretorioestadual",
      form: defaultForm,
      tracker: formToken || defaultTracker,
      token: "8fdf63b8-4a8c-44e2-93e0-7f3b018416cb",
      ...subData,
    };

    dispatch(formSubmit(submitData));

    if (messageField && messageTo) {
      dispatch(sendEmail(submitData, formName, messageTo));
    } else {
      setFormAlertMsg(
        "Erro no envio da mensagem: destinatário não configurado"
      );
    }
  };

  /* Sets city list based on state selection */
  useEffect(() => {
    if (subscriptionData.state !== "") {
      setCitiesList(
        citiesBR.filter(
          (city) =>
            city.microrregiao.mesorregiao.UF.sigla === subscriptionData.state
        )
      );
    }
  }, [subscriptionData]);

  return (
    <Fragment>
      <div className="landing__form">
        {formState.sendingSubscription ? (
          <Fragment>
            <FontAwesomeIcon
              className="animate-pulse"
              icon={faPaperPlane}
              style={{
                fontSize: "40pt",
                margin: "20px",
              }}
            />
            <span className="post-form ">Enviando Dados...</span>
          </Fragment>
        ) : formState.sentSubscription ? (
          formState.subscribed ? (
            <Fragment>
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  fontSize: "40pt",
                  margin: "10px",
                }}
              />
              <span className="post-form">{successMsgLine1}</span>
              <span className="post-form">{successMsgLine2}</span>
            </Fragment>
          ) : (
            <Fragment>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{
                  fontSize: "40pt",
                  margin: "10px",
                }}
              />
              <span className="post-form">Algo deu errado...</span>
              <span className="post-form">
                Por favor, tente novamente mais tarde.
              </span>
            </Fragment>
          )
        ) : (
          <Fragment>
            <div className="landing__form--subgroup--wrapper">
              <FormItem
                itemName="name"
                itemValue={subscriptionData.name}
                itemOnChange={setValue}
                placeholder="nome"
                required
              />
              <FormItem
                itemName="email"
                itemValue={subscriptionData.email}
                itemOnChange={setValue}
                itemType="email"
                placeholder="email"
                required
              />
              <FormItem
                itemName="phone"
                itemValue={subscriptionData.phone}
                itemOnChange={setValue}
                placeholder="whatsapp"
                required
              />
              <FormItem
                itemName="state"
                itemValue={subscriptionData.state}
                itemOnChange={setValue}
                selectPlaceholder="estado"
                itemType="selectEstadosBR"
                selectOptions={statesBR}
                required
              />
              <FormItem
                itemName="city"
                itemValue={subscriptionData.city}
                itemOnChange={setValue}
                selectPlaceholder="cidade"
                itemType="selectCidadesBR"
                selectOptions={citiesList}
                required
              />
              <FormItem
                itemName="neighborhood"
                itemValue={subscriptionData.neighborhood}
                itemOnChange={setValue}
                placeholder="bairro"
                required
              />
              {subscriptionData.state === "RJ" ? (
                <FormItem
                  itemName="zipCode"
                  itemValue={subscriptionData.zipCode}
                  itemOnChange={setValue}
                  placeholder="cep (opcional)"
                />
              ) : null}
            </div>
            {messageField ? (
              <FormItem
                itemName="message"
                itemValue={subscriptionData.message}
                placeholder="mensagem"
                itemType="textarea"
                itemOnChange={setValue}
                required
              />
            ) : null}
            <div className="optin-recaptcha">
              {useRecaptcha ? (
                <div className="recaptcha-wrapper">
                  <GoogleReCaptcha onVerify={(token) => setReToken(token)} />
                </div>
              ) : null}
              <FormItem
                itemName="optIn"
                itemType="checkbox"
                itemValue={subscriptionData.optIn}
                itemOnChange={setValue}
                placeholder={
                  <span className="form-optin">
                    Li e Concordo com a{" "}
                    <Link to="/politica-de-privacidade">
                      Política de Privacidade
                    </Link>
                  </span>
                }
              />
            </div>
            {formAlertMsg ? (
              <span className="form--alert-msg">{formAlertMsg}</span>
            ) : null}
            <div>
              <FormItem
                itemType="submit"
                onFormSubmit={handleSubmit}
                submitLabel="Enviar"
              />
            </div>
            <div style={{ display: "none", visibility: "hidden" }}></div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default FormWidget;
