import React from "react";

// Firebase
import firebase from "firebase/compat/app";
import { firebaseApp } from "./config";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";

// ReCaptcha
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// Router
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Redux
import { Provider } from "react-redux";
import store from "./store";

// Layout
import Layout from "./components/layout/Layout";
import LayoutEleicoes from "./components/layout/LayoutEleicoes";
import LayoutCandidato from "./components/layout/LayoutCandidato";
import LayoutBancada from "./components/layout/LayoutBancada";

// Pages
import Home from "./components/layout/pages/Home";
import Filiacao from "./components/layout/pages/Filiacao";
import Eleicoes from "./components/layout/pages/Eleicoes";
import NotFound from "./components/layout/pages/NotFound";
import Programa from "./components/layout/pages/Programa";
import QuemSomos from "./components/layout/pages/QuemSomos";
import Estrutura from "./components/layout/pages/Estrutura";

// Fireabse Config
const rrfProps = {
  firebase,
  config: firebaseApp,
  dispatch: store.dispatch,
  createFirestoreInstance,
  userProfile: "users",
  presence: "presence",
  sessions: "sessions",
};

const App = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LfSknchAAAAAN86qwX34d0sBqhaXxCPf6SHHh0C"
      language="pt_BR"
    >
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <div className="app__wrapper theme__main">
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route path="" element={<Home />} />
                  {/* Partido */}
                  <Route path="partido/quem-somos" element={<QuemSomos />} />
                  <Route path="partido/organizacao" element={<Estrutura />} />
                  <Route
                    path="partido/programa-de-governo"
                    element={<Programa />}
                  />
                  <Route path="partido/filiacao" element={<Filiacao />} />

                  {/* Noticias 
                  <Route path="noticias" element={null} />
                  <Route path="noticias/:noticia" element={null} />*/}

                  {/* Bancada */}
                  <Route
                    path="bancada/federal"
                    element={<LayoutBancada type="federal" />}
                  />
                  <Route
                    path="bancada/estadual"
                    element={<LayoutBancada type="estadual" />}
                  />
                  <Route
                    path="bancada/municipal"
                    element={<LayoutBancada type="municipal" />}
                  />

                  {/* Eleicoes */}
                  <Route path="eleicoes" element={<Eleicoes />} />

                  {/* Not Found */}
                  <Route path="*" element={<NotFound />} />
                </Route>
                <Route path="/eleicoes/estaduais" element={<LayoutEleicoes />}>
                  <Route path=":candidato" element={<LayoutCandidato />} />
                </Route>
                <Route path="/eleicoes/federais" element={<LayoutEleicoes />}>
                  <Route path=":candidato" element={<LayoutCandidato />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </div>
        </ReactReduxFirebaseProvider>
      </Provider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
