import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

// Candidatos
import { candidatos } from "../../../config";

// Components
import Section from "../content/Section";
import SnippetCandidato from "../content/SnippetCandidato";

// Hooks
import ScrollToTop from "../../hooks/scrollToTop";

// CSS
import "../../../static/css/layout/pages/eleicoes.css";

const Eleicoes = () => {
  ScrollToTop();

  const sortAndFilter = (arr, type) => {
    const filteredArray = arr.filter((item) => item.type === type);
    const sortedArray = filteredArray.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    return sortedArray;
  };

  const getFileSlug = (name) => {
    return name
      .normalize("NFD")
      .toLowerCase()
      .split(" ")
      .join("_")
      .replace(".", "")
      .replace(/[\u0300-\u036f]/g, "");
  };

  const candidatosFederal = sortAndFilter(candidatos, "federal");
  const candidatosEstadual = sortAndFilter(candidatos, "estadual");

  return (
    <Fragment>
      <Helmet>
        <title>PSOL/RJ | Eleições 2022</title>
      </Helmet>
      <Section title="Candidatos e Candidatas do PSOL/RJ às Eleições de 2022">
        {/*<div className="text__container">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
            dictum tortor nisl. Donec lacinia lacus vel dictum commodo. Donec
            risus felis, aliquet a feugiat id, sollicitudin sit amet quam.
            Pellentesque et enim sit amet orci vestibulum varius. Nullam feugiat
            ornare purus, a placerat massa convallis ac. Vestibulum ante ipsum
            primis in faucibus orci luctus et ultrices posuere cubilia curae;
          </p>
  </div>*/}
      </Section>
      <Section
        title="Deputados Federais"
        wrapperStyle={{ backgroundColor: "var(--theme-primary)" }}
      >
        <div className="eleicoes__grid">
          {candidatosFederal.map((item) => (
            <SnippetCandidato data={item} />
          ))}
        </div>
      </Section>
      <Section title="Deputados Estaduais">
        <div className="eleicoes__grid">
          {candidatosEstadual.map((item) => (
            <SnippetCandidato data={item} />
          ))}
        </div>
      </Section>
    </Fragment>
  );
};

export default Eleicoes;
